import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import PlacesAutocomplete from 'react-places-autocomplete';
import { colorPalette } from "../../styles";

const AddressLookup = ({ onSelect }) => {

    const [address, setAddress] = useState('');

    const handleSelection = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        onSelect(results, latLng);
    }

    return (
        <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelection}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div key={suggestions.description}>
                    <input style={styles.textInput}
                        {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input',
                        })}
                    />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: colorPalette.highlight_blue, cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

const styles = {

    textInput: {
        padding: 10,
        borderRadius: 5,
        border: '1px solid #ccc',
        marginBottom: 10,
        width: '100%',
    },


};

export default AddressLookup;
