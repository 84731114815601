import { Form, Button, Carousel } from 'react-bootstrap';
import { useEffect, useState, createRef } from 'react'
import { GetYears, GetVehicleData, GetParts, GetImages, DeletePost } from '../Getters.js';
import ImageUpload from '../WIP/ImageUpload.js';
import { useUser } from '@supabase/auth-helpers-react';
import Auth from './Auth.js';
import { supabase } from '../../lib/api.js';
import { GetPostData } from '../Getters.js';
import { PostPageStyles as styles, ButtonStyles, ImageUploadStyles } from '../../styles.js';
import ImageModal from '../WIP/ImageModal.js';
import { useParams } from 'react-router-dom';


const EditPost = () => {
    let { id } = useParams(); // This hooks extracts `id` from the URL
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const openModalWithImage = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    //Get the Post information from the database
    const [post, setPost] = useState(id);
    useEffect(() => {

    }, []);

    const [makes, setMakes] = useState([]);
    const [parts, setParts] = useState([]);

    const [splitDataObject, setSplitDataObject] = useState({}); //Object that contains the split data of the vehicle names
    const [manufacturer, setManufacturer] = useState(""); //Manufacturer is the manufacturer of the vehicle (first part of the name)
    const [model, setModel] = useState("");  //model is the model of the vehicle (second part of the name)
    const [make, setMake] = useState(""); //Make is the id of the vehicle

    //Input Variables
    const [vehicle_id, setVehicle_ID] = useState(''); //Points to the vehicle id
    const [year, setYear] = useState('');
    const [part_id, setPart_ID] = useState('');
    const [stock_number, setstock_number] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [images, setImages] = useState([]);
    const imageRef = createRef();

    useEffect(() => {
        async function InitilaizeSearchOptions() {
            const all_makes = await GetVehicleData();
            const all_parts = await GetParts();
            setMakes(all_makes);
            setParts(all_parts);

            const split_data = all_makes.map((item) => {
                const splitName = item.name.split(' - ');

                //  object includes  the split name and the ID
                return {
                    id: item.id,
                    splitName: splitName,
                };
            });

            //console.log("Split Data: ", split_data)

            let modelsByManufacturer = {};

            split_data.forEach(item => {
                let manufacturer = item.splitName[0];
                let model = item.splitName[1];
                let id = item.id;

                // Check if the manufacturer already exists in the structure
                if (!modelsByManufacturer.hasOwnProperty(manufacturer)) {
                    modelsByManufacturer[manufacturer] = [];
                }

                // Add the model and its ID under the manufacturer
                modelsByManufacturer[manufacturer].push({ model: model, id: id });
            });

            //console.log(modelsByManufacturer);
            //each manufacturer contains an array of objects with the model and id of the model. 
            setSplitDataObject(modelsByManufacturer);
        }
        async function GetPost() {
            const data = await GetPostData(id);

            if (data == null) {
                //console.log("Error getting post data");
                return;
            }
            else {
                console.log("Post Data: ", data);
                //Set the select options to the correct values
                //document.getElementById('make').value = data.vehicle.name;
                const [manufacturer, model] = data.vehicle.name.split(' - ');


                setManufacturer(manufacturer);
                setModel(model);
                document.getElementById('year').value = data.year;
                document.getElementById('part').value = data.parts.part_name;

                setVehicle_ID(data.vehicle_id);
                setMake(data.vehicle.id);
                setYear(data.year);
                setPart_ID(data.part_id);
                setstock_number(data.stock_number);
                setDescription(data.description);
                setPrice(data.price);
                setPost(data);
            }
        }

        InitilaizeSearchOptions().then(() => {
            GetPost();
        });
    }, []);

    useEffect(() => {
        async function LoadPictures() {
            //console.log("Image Path: ", post.user_id + "/" + post.id);
            const images = await GetImages(post.user_id + "/" + post.id);
            if (images == null) {
                //console.log("No Images Available");
                return;
            }
            else {
                //console.log("Images: ", images);
                setImages(images);
            }
        }
        LoadPictures();
    }, [post]);

    const user = useUser();
    if (!user) {
        return <Auth />;
    }

    const handleUpdate = async () => {
        if (!make) {
            alert("Please select a manufacturer and model before submitting.");
            return;
        }

        const update = {
            year: year,
            vehicle_id: make,
            part: part_id,
            stock_number: stock_number,
            description: description,
            price: price,
        }

        try {
            const { error } = await supabase
                .from('posts')
                .update(update)
                .eq('id', post.id)

            if (error) {
                //console.log(error);
                return;
            }
            else {
                alert("Update Complete");
            }
        }
        catch (error) {
            //console.log(error);
        }
    }

    const handleDeleteImage = async (image) => {

        //Display an Are you sure alert
        if (!window.confirm("Are you sure you want to delete this image?")) {
            return;
        }

        //Delete the image from the database
        const { data, error } = await supabase
            .storage
            .from('posts')
            .remove([image.url])

        if (error) {
            //console.log(error);
            return;
        }
        else {
            //Remove the image from the state
            const newImages = images.filter((img) => img.id != image.id);
            setImages(newImages);
        }
    }

    return (
        <Form style={styles.formContainer}>
            <h1 style={styles.headingText}>Edit Post</h1>
            <Form.Group controlId="vehicleDetails" style={{ flexDirection: 'column', display: 'flex', paddingInline: '20px' }}>
                <Form.Label style={styles.leftAlignedLabel}>Manufacturer</Form.Label>
                <Form.Select
                    name="Manufacturer"
                    id="manufacturer"
                    value={manufacturer}
                    style={styles.dropdown}
                    onChange={(e) => {
                        const selectedManufacturer = e.target.value;
                        setManufacturer(selectedManufacturer); // Update the manufacturer state
                        setMake(''); // Reset the make state when manufacturer changes
                    }}
                >
                    <option value={''}>Select Manufacturer...</option>
                    {Object.keys(splitDataObject).map(manufacturer => (
                        <option key={manufacturer} value={manufacturer}>{manufacturer}</option>
                    ))}
                </Form.Select>

                {manufacturer && (
                    <>
                        <Form.Label style={styles.leftAlignedLabel}>Model</Form.Label>
                        <Form.Select
                            name="Model"
                            id="model"
                            value={model}
                            style={styles.dropdown}
                            onChange={(e) => {
                                const modelName = e.target.value;
                                // Find the model object based on the manufacturer and model name
                                const modelObj = splitDataObject[manufacturer].find(model => model.model === modelName);

                                if (modelObj) {
                                    setModel(modelObj.model);
                                    setMake(modelObj.id); // update the makes's id
                                } else {
                                    // Reset if no model is found 
                                    setModel('');
                                    setMake('');
                                }
                            }}
                        >
                            <option value={''}>Select Model...</option>
                            {splitDataObject[manufacturer]?.map(item => (
                                <option key={item.id} value={item.model}>{item.model}</option>
                            ))}
                        </Form.Select>
                    </>
                )}

                <Form.Label style={styles.leftAlignedLabel}>Year</Form.Label>
                <Form.Select
                    style={styles.dropdown}
                    name="Year"
                    id="year"
                    onChange={(e) => setYear(e.target.value)}
                >
                    {GetYears().map((item) => {
                        return <option key={item} value={item} >{item}</option>;
                    })}
                </Form.Select>
                <Form.Label style={styles.leftAlignedLabel}>Part</Form.Label>

                <Form.Select
                    style={styles.dropdown}
                    name="Part"
                    id="part"
                    onChange={(e) => {
                        const selected = parts.find(part => part.part_name === e.target.value);
                        setPart_ID(selected.id);
                    }}
                >
                    {parts != [] && parts.map((item) => {
                        return <option key={item.part_name} value={item.part_name} >{item.part_name}</option>;
                    }
                    )}
                </Form.Select>
            </Form.Group>

            <Form.Group controlId="postDetails" style={{ flexDirection: 'column', display: 'flex', paddingInline: '20px', marginBottom: '20px' }}>
                <Form.Label style={styles.leftAlignedLabel}>Part ID</Form.Label>
                <Form.Control
                    style={styles.textInput}
                    type="text"
                    name="stock number"
                    value={stock_number}
                    placeholder="Enter Stock #..."
                    onChange={(e) => { setstock_number(e.target.value) }} />

                <Form.Label style={styles.leftAlignedLabel}>Asking Price</Form.Label>
                <Form.Control
                    style={styles.textInput}
                    type="number"
                    value={price}
                    placeholder="Enter Price"
                    onChange={(e) => {
                        //Convert the value to a number
                        const value = Number(e.target.value);
                        setPrice(value > post.price ? post.price : value);
                    }}
                />
                <p style={{ textAlign: 'start', fontSize: 'small' }}>Maximum price: ${post.price}</p>

                <Form.Label style={styles.leftAlignedLabel}>Description</Form.Label>
                <Form.Control
                    style={styles.textInput}
                    as="textarea"
                    name="description"
                    rows={3}
                    value={description}
                    placeholder="Enter Description"
                    onChange={(e) => { setDescription(e.target.value) }} />

                {/* DELETE CURRENT IMAGES */}
                {images.length > 0 && (
                    <div style={{ marginBottom: '10px' }}>
                        <h3 style={{ ...styles.headingText, marginBottom: '10px' }}>Current Images</h3>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {images.map((image, index) => {
                                return (
                                    <div style={{ position: 'relative', width: 300, height: 300, boxShadow: '0px 4px 6px rgba(0,0,0,0.2)' }} key={index}>
                                        <button
                                            type="button"
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '5px',
                                                width: '30px',
                                                height: '30px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 2
                                            }}
                                            onClick={() => { handleDeleteImage(image) }}
                                        >
                                            X
                                        </button>
                                        <img style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }} src={image.image} alt={`Image ${index + 1}`} onClick={() => openModalWithImage(image.image)}
                                        />
                                        <ImageModal show={showModal} onHide={closeModal} imageUrl={selectedImage} />

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}

                {/* Image Upload - Maximum of 4 images - Use a ref to detect if images is not null */}
                <ImageUpload ref={imageRef} path={user.id} onUpload={() => { console.log("Upload Complete") }} />
            </Form.Group>

            {/* Submit Button */}
            <button style={ButtonStyles.submitButton} type="button" onClick={handleUpdate}>Apply Changes</button>
        </Form>
    )
}

export default EditPost;