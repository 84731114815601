import { useEffect, useState, createRef } from 'react'
import { Container, Row, Col, Carousel, Button, Card, Form } from 'react-bootstrap';
import { ButtonStyles, FormStyles as styles } from "../../styles";


const ContactUs = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };



    return (

        <Col md={12} style={styles.formContainer}>
            <h1 style={styles.headingText}>Contact Us</h1>
            {/* TODO - Change this to use the Supabase Email system */}
            <Form action="https://formspree.io/f/xqkrqjqd"
                method="POST">
                <Form.Group controlId="formName">
                    <Form.Label style={styles.leftAlignedLabel}>Name</Form.Label>
                    <Form.Control style={styles.textInput} type="text" name="name" value={formData.name} onChange={handleChange} required />
                </Form.Group>
                <Form.Group controlId="formEmail">
                    <Form.Label style={styles.leftAlignedLabel}>Email</Form.Label>
                    <Form.Control style={styles.textInput} type="email" name="email" value={formData.email} onChange={handleChange} required />
                </Form.Group>
                <Form.Group controlId="formPhone">
                    <Form.Label style={styles.leftAlignedLabel}>Phone Number</Form.Label>
                    <Form.Control style={styles.textInput} type="tel" name="phone" value={formData.phone} onChange={handleChange} />
                </Form.Group>
                <Form.Group controlId="formSubject">
                    <Form.Label style={styles.leftAlignedLabel}>Subject</Form.Label>
                    <Form.Control style={styles.textInput} type="text" name="subject" value={formData.subject} onChange={handleChange} required />
                </Form.Group>
                <Form.Group controlId="formMessage">
                    <Form.Label style={styles.leftAlignedLabel}>Message</Form.Label>
                    <Form.Control style={styles.textInput} as="textarea" rows={3} name="message" value={formData.message} onChange={handleChange} required />
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" name="_subject" value="New submission from Contact Us Form!" />

                </Form.Group>
                <button style={ButtonStyles.submitButton} type="submit">Send</button>
            </Form>
        </Col>

    );
};

export default ContactUs;