import { useState, useEffect } from 'react'
import { useUser } from '@supabase/auth-helpers-react';
import { supabase } from '../../lib/api';
import { Form, Button, Dropdown, Modal } from "react-bootstrap";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import AddressLookup from "../WIP/AddressLookup";
import { ButtonStyles, FormStyles as styles, colorPalette } from "../../styles";
import { useNavigate, Link } from "react-router-dom";

const Registration = () => {
    let navigate = useNavigate();
    //#region REGISTRATION
    const [user_metadata, setUserMetadata] = useState({
        username: '',
        first_name: '',
        last_name: '',
    });
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [latlng, setLatLng] = useState({ lat: 0, lng: 0 });

    const [password, setPassword] = useState('');
    const [hasLength, setHasLength] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSymbol, setHasSymbol] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);


    const [showTermsModal, setShowTermsModal] = useState(false);

    const handleShowTermsModal = () => setShowTermsModal(true);
    const handleCloseTermsModal = () => setShowTermsModal(false);

    const user = useUser();

    const handleUpdateToData = (e) => {
        setUserMetadata({
            ...user_metadata,
            [e.target.name]: e.target.value
        })
    }

    const handleUpdate = (results, latlng) => {

        if (results && latlng) {
            const addressParse = fillInAddress(results[0]);
            setStreet(addressParse.address1);
            setCity(addressParse.city);
            setState(addressParse.province);
            setZipCode(addressParse.postalcode);
            setCountry(addressParse.country);
            setLatLng(latlng);
        }
    }
    useEffect(() => {

    }, [latlng, user]);

    function fillInAddress(address) {
        let address1 = "";
        let address2 = "";
        let postcode = "";
        let city = "";
        let province = "";
        let country = "";

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of address.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }

                case "route": {
                    address1 += component.short_name;
                    break;
                }

                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }

                case "postal_code_suffix": {
                    postcode = `${postcode}-${component.long_name}`;
                    break;
                }
                case "locality":
                    city = component.long_name;
                    break;
                case "administrative_area_level_1": {
                    province = component.short_name;
                    break;
                }
                case "country":
                    country = component.long_name;
                    break;
            }
        }
        return {
            address1: address1,
            address2: address2,
            postalcode: postcode,
            city: city,
            province: province,
            country: country,
            formatted_address: address.formatted_address,
        };
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let tempLatLng = latlng;
            if (tempLatLng === null || tempLatLng === undefined) {
                const results = await geocodeByAddress(`${street}, ${city}, ${state}, ${zip_code}, ${country}`);
                tempLatLng = await getLatLng(results[0]);
            }


            let location = `POINT(${tempLatLng.lat} ${tempLatLng.lng})`;

            const packet = {
                user_id: user.id,
                username: user_metadata.username,
                first_name: user_metadata.first_name,
                last_name: user_metadata.last_name,
                street: street,
                city: city,
                state: state,
                zip: zip_code,
                country: country,
                location: location
            }

            console.log("Packet: ", packet);
            const { data, error } = await supabase.from('userdata').insert([packet]);
            if (error) {
                console.log(error.message);
                return;
            }
            else {
                alert("Successfully updated user data!");
                // Find a way to redirect the user to their desired page? 
                navigate("/");
            }
        }
        catch (error) {
            console.log(error.message);
        }
    }
    //#endregion

    //#region Sign Up
    const [signUpData, setSignUpData] = useState({
        email: '',
        password: '',
        confirm_password: ''
    });

    const handleUpdateToSignUp = (e) => {

        const { name, value } = e.target;

        if (name === "password") {
            setPassword(value);
            setHasLength(value.length >= 8);
            setHasUppercase(/[A-Z]/.test(value));
            setHasLowercase(/[a-z]/.test(value));
            setHasNumber(/\d/.test(value));
            setHasSymbol(/[!@#$%^&*(),.?":{}|<>]/.test(value));
        }

        setSignUpData({
            ...signUpData,
            [e.target.name]: e.target.value
        })
    }

    const ValidatePassword = (password) => {
        //Validate the password input has the following features:
        //  - At least 8 characters
        //  - At least 1 uppercase letter
        //  - At least 1 lowercase letter
        //  - At least 1 number
        //  - At least 1 special character
        //  - No spaces
        if (signUpData.password !== signUpData.confirm_password) {
            alert("Passwords do not match!");
            return false;
        }
        if (password.length < 8) {
            alert("Password must be at least 8 characters long!");
            return false;
        }
        if (!password.match(/[A-Z]/)) {
            alert("Password must contain at least 1 uppercase letter!");
            return false;
        }
        if (!password.match(/[a-z]/)) {
            alert("Password must contain at least 1 lowercase letter!");
            return false;
        }
        if (!password.match(/[0-9]/)) {
            alert("Password must contain at least 1 number!");
            return false;
        }
        if (!password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            alert("Password must contain at least 1 special character!");
            return false;
        }
        if (password.match(/\s/)) {
            alert("Password must not contain any spaces!");
            return false;
        }
        return true;
    }

    const handleSignUp = async (e) => {
        e.preventDefault();

        // Check if the terms and conditions are agreed
        if (!agreedToTerms) {
            alert("Please agree to the terms and conditions to continue.");
            return; // Stop the function if not agreed
        }

        if (!ValidatePassword(signUpData.password)) return;

        const { data, error } = await supabase.auth.signUp({
            email: signUpData.email,
            password: signUpData.password,
        })
        if (error) {
            alert(error.message)
        }
        else {
            alert("User successfully signed up! Please check your email to verify your account.");
        }
    };


    if (user === null) {
        return (
            <Form style={styles.formContainer}>
                <h1 style={styles.headingText}>Register</h1>



                <Form.Group className="mb-3">
                    <div className="row">
                        <div className="col">
                            <Form.Label style={styles.leftAlignedLabel}>Email:</Form.Label>
                            <Form.Control style={styles.textInput} type="email" placeholder='Email' name="email" onChange={handleUpdateToSignUp} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Form.Label style={styles.leftAlignedLabel}>Password:</Form.Label>

                            <Form.Control
                                style={styles.textInput}
                                type="password"
                                placeholder='New password'
                                name="password"
                                onChange={handleUpdateToSignUp}
                            // other props
                            />
                            {/* Password strength checklist */}
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginLeft: "10px" }}>
                                <label style={{ display: 'flex', alignItems: 'center', color: hasLength ? 'green' : 'black' }}>
                                    <input type="checkbox" checked={hasLength} readOnly style={{ marginRight: '8px' }} />
                                    <span>At least 8 characters</span>
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center', color: hasUppercase ? 'green' : 'black' }}>
                                    <input type="checkbox" checked={hasUppercase} readOnly style={{ marginRight: '8px' }} />
                                    <span>1 uppercase</span>
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center', color: hasLowercase ? 'green' : 'black' }}>
                                    <input type="checkbox" checked={hasLowercase} readOnly style={{ marginRight: '8px' }} />
                                    <span>1 lowercase</span>
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center', color: hasNumber ? 'green' : 'black' }}>
                                    <input type="checkbox" checked={hasNumber} readOnly style={{ marginRight: '8px' }} />
                                    <span>1 number</span>
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center', color: hasSymbol ? 'green' : 'black' }}>
                                    <input type="checkbox" checked={hasSymbol} readOnly style={{ marginRight: '8px' }} />
                                    <span>1 symbol</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Form.Label style={styles.leftAlignedLabel}>Confirm Password:</Form.Label>

                            <Form.Control style={styles.textInput} className='col-sm' type="password" placeholder='Confirm your password' name="confirm_password" onChange={handleUpdateToSignUp} />
                        </div>
                    </div>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">

                    <label style={{ display: 'flex', alignItems: 'center', }}>
                        <input
                            type="checkbox"
                            style={{ marginRight: '8px' }}
                            onChange={e => setAgreedToTerms(e.target.checked)}
                        />
                        <span>
                            I agree to the
                            <button
                                type="button"
                                style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer', padding: 0, marginLeft: '5px' }}
                                onClick={handleShowTermsModal}
                            >
                                terms and conditions
                            </button>
                        </span>
                    </label>
                </Form.Group>

                <div>
                    <button type="button" style={ButtonStyles.submitButton} onClick={handleSignUp}>Sign Up</button>
                    <p>Already have an account? <Link to="/auth">Sign in</Link></p>
                </div>

                <Modal show={showTermsModal} onHide={handleCloseTermsModal} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: colorPalette.primary_blue }}>Terms and Conditions</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <p>Welcome to www. privateautoparts.com (“PAP”). These Terms of Use, the Privacy Policy and all policies posted on our site set out the terms on which we offer you access to and use of our site, services, applications and tools (collectively "Services"). All policies, the Mobile Devices Terms, and the Privacy Policy are incorporated into these Terms of Use. You agree to comply with all the above when accessing or using our Services.</p>
                        <p>PAP is provided to you by Private Auto Parts: </p>
                        <p>279 Parkway Ave., Keswick, ON L4P 2W1 </p>

                        <h5>ACCOUNT</h5>
                        <p>In order to access and use the Services, you may be required to register with us and set up an account with your email address and a password (“Account”). The email address you provide will be your email address, and you are solely responsible for maintaining the confidentiality of your password. You are solely responsible for all activities that occur under your Account. </p>
                        <p>You may connect to the Services with a third-party service and you give us permission to access, store, and use your information from that service as permitted by that service and as may be described in our Privacy Policy. </p>

                        <h5>USING THE SERVICES</h5>
                        <p>You will post in the appropriate automobile part and will not do any of the following behaviour:</p>

                        <ul>
                            <li>violate any laws;</li>
                            <li>be false or misleading;</li>
                            <li>infringe any third-party right;</li>
                            <li>distribute or contain spam, chain letters, or pyramid schemes;</li>
                            <li>distribute viruses or any other technologies that may harm PAP or the interests or property of PAP users;</li>
                            <li>copy, modify, or distribute any other person's content;</li>
                            <li>use any robot, spider, scraper or other automated means to access PAP and coliect content for any purpose without our express written permission;</li>
                            <li>harvest or otherwise collect information about others, including email addresses, without their consent;</li>
                        </ul>

                        <h5>ABUSE OF PAP SERVICES</h5>
                        <p>Please contact us to tell us about any problems or offensive content so that together we can keep the Services site working properly. We may limit or terminate our Services, remove hosted content and take technical and legal steps to keep users off PAP if we think that they are creating problems or acting inconsistently with the letter or spirit of our policies. However, whether we decide to take any of these steps, remove hosted content or keep a user off PAP, we do not accept any liability for monitoring PAP or for unauthorized or unlawful content on PAP or use of PAP by users.</p>

                        <h5>FEE’S & RULES OF SERVICES</h5>
                        <p>Our fees are quoted in Canadian Dollars, and we may change them at anytime in our sole discretion.  We may choose to temporarily change our fees for promotional events or new services; these changes are effective when we announce the promotional event or new service. Our fees are non-refundable, and you are responsible for paying them when they're due. If you don't, we may limit your ability to use the Services. If your payment method fails or your account is past due, we may collect fees owed using any legal collection method. </p>
                        <p>The following Fee’s rules are applicable to all PAP users:</p>
                        <ul>
                            <li>First time posting an advertisement costs 3% of listed price</li>
                            <li>Cost to renew advertisement will be 1% of the posted price (after 12 months)</li>
                            <li>Minimum $0.50 cent listing fee.</li>
                        </ul>
                        <p>Canadian taxes associated with our Services will be collected when applicable. You agree to provide accurate address information necessary for PAP to comply with its obligations under applicable law. </p>
                        <p>You are solely responsible to collect and remit any applicable taxes resulting from the sale of your items or services listed on PAP.
                            You agree to comply with all applicable laws and regulations in respect of any purchase you may ultimately make from a seller through PAP, including any licensing and/or insurance requirements.</p>
                        <p>The following rules are applicable to all PAP users:</p>
                        <ul>
                            <li>Maximum sale price cannot exceed posted price (you may sell for less, but not more)</li>
                            <li>All sold items must be deleted within seven (7) days of sale</li>
                            <li>Location of items must be posted with accurate postal code / zip codes</li>
                            <li>Item descriptions must be accurate and honest</li>
                            <li>No use of profane language, users must treat others with respect</li>
                            <li>Abusive behaviour will result on being banned from the PAP site</li>
                            <li>Please contact us if any terms are being violated</li>
                            <li>PAP places no guarantees on the products listed, user must proceed at their own risk and discretion.</li>
                            <li>Money transfer and payments are done at your own risk</li>
                            <li>Reported offense against user will may result of suspension of use or expulsion in the sole determination of PAP</li>
                            <li>No complete vehicles for sale</li>
                            <li>No refunds will be given for breach of Terms of Use</li>
                            <li>Currency must be specified on posted price</li>
                            <li>All commissions taken on PAP upon listing are final </li>
                            <li>Ad’s will be visible for one (1) year after posting</li>
                        </ul>
                        <h5>CONTENT</h5>
                        <p>You agree not to copy, modify, resell, or distribute the Services, our copyrights or trademarks. When you give us content, you are granting us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable right to exercise the copyright, publicity, and database rights to that content. We reserve the right to remove content where we have grounds for suspecting the violation of these terms or the rights of any other party.</p>

                        <h5>INTELLECTUAL PROPERTY RIGHTS </h5>
                        <p>Do not post content that infringes the rights of third parties. This includes, but is not limited to, content that infringes on intellectual property rights such as copyright and trademark (e.g. offering counterfeit items for sale). Do not post content that contains any reference to or likeness of any identifiable third parties, unless consent has been obtained from each such individual as may be required. We reserve the right to remove content where we have grounds for suspecting the violation of these terms, our policies or of any party's rights. </p>

                        <h5>DISCLAIMERS & LIMITATION OF LIABILITY. </h5>
                        <p>The Services are provided “as is” and “as available”. You agree not to hold us responsible for things other users post or their actions. You also agree not to hold us responsible for the payment processing.  As most of the stuff on the Services comes from other users, we do not guarantee the accuracy, completeness, efficacy or timeliness of any postings or user communications or the quality, safety, or legality of what is offered. We also cannot guarantee continuous or secure access to our Services. Notification functionality in our Services may not occur in real time. Such functionality is subject to delays beyond our control, including without limitation, delays or latency due to your physical location or your wireless data service provider’s network. Accordingly, to the extent legally permitted we expressly disclaim all warranties, representations and conditions, express or implied, including those of quality, merchantability, merchantable quality, durability, fitness for a particular purpose and those arising by statute. We are not liable for any loss, whether of money (including profit), goodwill, or reputation, or any special, indirect, or consequential damages arising out of your use of PAP, even if you advise us or we could reasonably foresee the possibility of any such damage occurring. </p>
                        <p>Despite the previous paragraph, if we are found to be liable, our liability to you or any third party (whether in contract, tort, negligence, strict liability in tort, by statute or otherwise) is limited to the total fees you pay to us in the twelve (12) months prior to the action giving rise to liability.</p>

                        <h5>INDEMNIFICATION.</h5>
                        <p>You will indemnify and hold harmless PAP and its affiliates and our and their respective officers, directors, agents and employees (each an “Indemnified Party”), from any claim made by any third party, together with any amounts payable to the third party whether in settlement or as may otherwise be awarded, and reasonable legal costs incurred by any of the Indemnified Parties, arising from or relating to your use of the Services, any alleged violation by you of the applicable terms, and any alleged violation by you of any applicable law or regulation. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter subject to indemnification by you, but doing so will not excuse your indemnity obligations.</p>

                        <h5>PERSONAL INFORMATION. </h5>
                        <p>By using Services, you agree to the collection, transfer, storage and use of your personal information by PAP (the "data controller") on servers located in Canada. </p>

                        <h5>GENERAL.</h5>
                        <p>These terms and the other policies posted on the Services constitute the entire agreement between us and you, superseding any prior agreements. This agreement is governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein. We both submit to the jurisdiction of the courts of the Province of Ontario.  Your notices to us must be sent by registered mail to:</p>
                        <p>279 Parkway Ave., Keswick, ON L4P 2W1 </p>

                        <p>We will send notices to you via the email address you provide, or by registered mail. Notices sent by registered mail will be deemed received five (5) days following the date of mailing. We may update this agreement at any time, with updates taking effect when you next use the site or after 30 days, whichever is sooner. No other amendment to this agreement will be effective unless made in writing, signed by users and by us. </p>

                        <h2>PRIVACY POLICY</h2>
                        <h5>GENERAL.</h5>

                        <p>This privacy policy describes the policy of Private Auto Parts (“PAP”) regarding the collection, use, storage, sharing and protection of your personal information ("Privacy Policy"). This Privacy Policy applies to the www.privateautoparts.com  website and all related websites, applications, services and tools where reference is made to this policy ("Services"), regardless of how you access the Services, including access through mobile devices. </p>

                        <h5>SCOPE AND CONSENT.</h5>

                        <p>By using PAP and related Services, you give explicit consent to PAP for the collection, use, disclosure and retention of your personal information by us, as described in this Privacy Policy and our Terms of Use.</p>

                        <p>PAP may change this Privacy Policy from time to time in its sole discretion. The amended Privacy Policy will be effective immediately after it is first posted on our Website. This Privacy Policy is effective as of March 31st, 2024.</p>

                        <h5>WHAT INFORMATION WE COLLECT.</h5>

                        <p>You can visit our Website without registering for an account. When you decide to provide us with your personal information, you agree that such information is sent to and stored on our servers. We collect the following types of personal information:</p>
                        <ul>
                            <li>Information automatically collected: When you visit our Website, use our Services and or reply to ads or other content, we automatically collect the information sent to us by your computer, mobile device or other equipment that provides access. </li>
                            <li>Information you provide to us: We collect and store any information you enter on our Website or that you provide to us when you use our Services. </li>
                            <li>Information from other sources: We may receive or collect additional information about you from third parties and add this to our account information. </li>
                        </ul>
                        <h5>HOW WE USE INFORMATION WE COLLECT.</h5>

                        <p>You agree that we may use your personal information for the following purposes:</p>
                        <ul>
                            <li>To prevent, detect and investigate potentially prohibited or illegal activities, fraud and breaches of our Terms of Use;</li>
                            <li>to personalize, measure and improve our Services, content and ads; </li>
                            <li>to contact you, by e-mail, push notification, text message (SMS) or by telephone, to inquire about our Services and the services of companies that are part of PAP for the purpose of targeted marketing activities, updates, and promotional offers, or for any other purposes as set forth in this Privacy Policy; </li>
                            <li>to provide you with other services that you have requested, as described when we collected the information, </li>
                            <li>to administer contests</li>
                        </ul>

                        <h5>MARKETING PURPOSES.</h5>

                        <p>You agree that we may use the information collected by us to send you offers, whether personalized or not, or to contact you regarding products or Services offered by PAP.</p>

                        <p>We will not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We may combine your information with information we collect from other companies and use it to improve and personalize our Services and functionalities.</p>

                        <h5>COOKIES.</h5>

                        <p>When you use our Services, we and our service providers may place cookies (data files on your, computer, phone or mobile device’s drive) or web beacons (electronic images that are placed in a web page’s code) or similar technologies. We use cookies to help us identify you as a user, to provide you a better experience on our Website, to measure promotional effectiveness and to ensure trust and safety on our Website..</p>

                        <h5>ACCESS TO PERSONAL INFORMATION.</h5>
                        <ul>
                            <li>Accessing or obtaining a copy of your information: You may access your personal information or account information by logging into your PAP account.</li>
                            <li>Rectifying your information: If your information is factually incorrect or incomplete or irrelevant for the purposes for which we process your information, you may request that we rectify your information by contacting us.</li>
                            <li>Changing your information: We cannot modify your personal information or account information. You can modify your own information by logging into your PAP account. </li>
                        </ul>
                        <h5>PROTECTION AND RETENTION OF PERSONAL INFORMATION.</h5>

                        <p>We protect your information by using technical and administrative security measures (such as firewalls, data encryption, and physical and administrative access controls to the data and servers) that limit the risk of loss, abuse, and unauthorised access, disclosure, and alteration. Nevertheless, if you believe your account or information has been abused, please contact us.</p>

                        <p>We retain personal information no longer than is legally permissible and delete personal information when it is no longer necessary for the purposes as described above.</p>

                        <h5>OTHER INFORMATION.</h5>
                        <ul>
                            <li>Abuse and unsolicited commercial communications ("spam"): We do not tolerate abuse of our Website. You do not have permission to add other PAP users to your mailing list for commercial purposes, even if a user has purchased something from you, unless the user has given his explicit consent. </li>
                            <li>Third Parties: Unless explicitly provided otherwise in this Privacy Policy, this Privacy Policy applies only to the use and transfer of information we collect from you. PAP has no control over the privacy policies of third parties that may apply to you. When we work with third parties or use third party tools to provide certain services, we will explicitly indicate which privacy policy applies to you. We therefore encourage you to ask questions before you disclose your personal information to others.</li>
                            <li>Contact: If you have any questions about this Privacy Policy or our processing of your personal information, please contact us.</li>
                        </ul>

                    </Modal.Body>

                </Modal>
            </Form>
        )
    }
    //#endregion


    return (
        <Form style={styles.formContainer}>
            <h1 style={styles.headingText}>Complete Registration</h1>
            <Form.Group className="mb-3" controlId="RegisterInformation">
                <div className="row">
                    <div className="col">
                        <Form.Label style={styles.leftAlignedLabel}>Username:</Form.Label>

                        <Form.Control style={styles.textInput} type="text" name="username" onChange={handleUpdateToData} />
                        <p style={{ fontSize: '12px' }}>Your username will be displayed on your public profile</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6"> {/* Half width for medium to large screens */}
                        <Form.Label style={styles.leftAlignedLabel}>First Name:</Form.Label>
                        <Form.Control style={styles.textInput} type="text" name="first_name" onChange={handleUpdateToData} />
                    </div>


                    <div className="col-sm-6"> {/* Half width for medium to large screens */}
                        <Form.Label style={styles.leftAlignedLabel}>Last Name:</Form.Label>
                        <Form.Control style={styles.textInput} type="text" name="last_name" onChange={handleUpdateToData} />
                    </div>
                </div>


                {/* Input the places autocomplete tool here.  */}
                <div className="row">
                    <div className="col">
                        <Form.Label style={styles.leftAlignedLabel}>Search for Address:</Form.Label>
                        <AddressLookup onSelect={handleUpdate} />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Form.Label style={styles.leftAlignedLabel}>Street Address:</Form.Label>
                        <Form.Control style={styles.textInput} type="text" name="street" value={street} onChange={setStreet} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6"> {/* Half width for medium to large screens */}
                        <Form.Label style={styles.leftAlignedLabel}>City:</Form.Label>

                        <Form.Control
                            style={styles.textInput}
                            type="text" name="city" value={city} onChange={setCity} />
                    </div>
                    <div className="col-sm-6"> {/* Half width for medium to large screens */}
                        <Form.Label style={styles.leftAlignedLabel}>Postal Code:</Form.Label>

                        <Form.Control
                            style={styles.textInput}
                            type="text" name="zip_code" value={zip_code} onChange={setZipCode} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <Form.Label style={styles.leftAlignedLabel}>State/Province:</Form.Label>

                        <Form.Control
                            style={styles.textInput}
                            type="text" name="state" value={state} onChange={setState} />
                    </div>
                    <div className="col-sm-6">
                        <Form.Label style={styles.leftAlignedLabel}>Country:</Form.Label>

                        <Form.Control
                            style={styles.textInput}
                            type="text" name="country" value={country} onChange={setCountry} />
                    </div>
                </div>



            </Form.Group>
            <button type="button" style={ButtonStyles.submitButton} onClick={handleSubmit}>
                Submit
            </button>
        </Form>
    )
}

export default Registration