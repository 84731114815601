import { Alert } from "react-bootstrap";
import { supabase } from "../lib/api.js";

//#region Search menu data
/**
 * @returns an array of years from 1900 to the current year in the form of <option> tags for a dropdown menu
 */
export const GetYears = () => {
  let years = [];
  let currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 1900; i--) {
    years.push(i);
  }
  return years;
};

/**
 * @param {int8} make_id
 * @returns
 */
export const GetVehicleData = async (make_id) => {
  let models = [];
  const { data, error } = await supabase
    .from("vehicle")
    .select(
      `
            id,
            name
        `
    )
    .order("name", { ascending: true });

  // .eq('manufacturer_id', make_id)
  if (error) console.log("error", error);
  else {
    models = data;
  }
  const { data: secondSet, error: secondError } = await supabase
    .from("vehicle")
    .select(
      `
            id,
            name
        `
    )
    .order("name", { ascending: true })
    .range(1001, 2000);
  if (secondError) console.log("error", error);
  else {
    models = models.concat(secondSet);
  }
  return models;
};

/**
 * @returns Up to 1000 Manufacturers
 */
export const GetManfacturers = async () => {
  let manufacturers = [];
  const { data, error } = await supabase
    .from("manufacturer")
    .select(
      `
            id,
            manufacturer_name
        `
    )
    .order("manufacturer_name", { ascending: true });
  if (error) console.log("error", error);
  else {
    manufacturers = data;
  }
  return manufacturers;
};

/**
 * @returns an array of the part names
 */
export const GetParts = async () => {
  let parts = [];
  const { data, error } = await supabase
    .from("parts")
    .select("id, part_name")
    .order("part_name", { ascending: true });
  if (error) console.log("error", error);
  else {
    parts = data;
  }
  return parts;
};
//#endregion

//#region Loading Posts
/**
 * TODO:
 * 1. Include a filter for distance to the seller
 * 4. Provide sort Options
 * 5. Include a filter for price
 * 6. Create a pagination limiter for the number of posts returned
 * @param {Object containing search values: year, make, part, user_id} search
 * @returns
 */
export const LoadPosts = async (search) => {
  if (search == null || search == undefined) {
    console.log("Search on Load: ", search);
    return null;
  }

  console.log("Range: ", search.from, search.to);
  var query = null;

  if (search.favorites === true) {
    console.log("Searching for favorites: ", search.user_id);
    query = supabase
      .from("favorites")
      .select(
        "posts (id, stock_number, description, currencytype, price, year, vehicle (name), parts (part_name), imagePath, user_id, location, userdata (username), favorites (id))"
      )
      .eq("user", search.user_id);
  } else {
    query = supabase
      .from("posts")
      .select(
        "id, stock_number, description, currencytype, price, year, vehicle (name), parts (part_name), imagePath, user_id, location, userdata (username), favorites (id)"
      );
    if (search.year != "" || search.year === undefined) {
      console.log("added Year Filter");
      query.eq("year", search.year);
    }
    if (search.make != "" || search.make === undefined) {
      console.log("added Vehicle Filter");
      query.eq("vehicle_id", search.make);
    }
    if (search.part != "" || search.part === undefined) {
      console.log("added Part Filter");
      query.eq("part", search.part);
    }
    if (search.user_id != "" || search.user_id === undefined) {
      console.log("added User Filter");
      query.eq("user_id", search.user_id);
    }
    query.range(search.from, search.to);
  }

  //Include a filter for distance ------------------------------------------------------------------------------------
  const { data, error } = await query;
  if (error) {
    console.log(error);
    return null;
  } else {
    //Load the image urls into the posts object under the images key
    let promises = [];
    data.map((post) => {
      post.images = promises.push(GetImages(post.imagePath));
    });

    await Promise.all(promises);

    //Unwrap the data if it is a favorites search
    if (search.favorites === true) {
      let posts = [];
      data.forEach((favorite) => {
        posts.push(favorite.posts);
      });
      return posts;
    } else return data;
  }
};

export const LoadPostsV2 = async (search) => {
  console.log("Search: ", search);
  const searchData = {
    search_user_id: search?.user_id ? search.user_id : null,
    search_part: search.part != "" ? search.part : 0,
    search_make: search.make != "" ? search.make : 0,
    search_manufacturer: search?.manufacturer ? search.manufacturer : "",
    search_year: search.year != "" ? search.year : 0,
    range_from: search?.from ? search.from : 0,
    range_to: 10,
    order_by: "price",
  };
  console.log("Searching With: ", searchData);

  const { data, error } = await supabase.rpc("search_posts", searchData);
  if (error) {
    console.log(error);
    return null;
  } else {
    console.log(data);
    return data;
  }
};

export async function FilterPosts(data, locations) {
  let nearbyPostsIDs = [];

  locations.map((post) => {
    nearbyPostsIDs.push(post.id);
  });

  let filtered = data.filter((post) => {
    return nearbyPostsIDs.includes(post.id);
  });

  return filtered;
}

export async function GetNearbyPosts(distance, lat, long) {
  //If no allowable distance range is given, search all posts
  if (distance == null || distance == undefined || distance == 0) {
    const { data, error } = await supabase.rpc("distance_query", {
      lat: lat,
      long: long,
    });

    if (error) {
      console.log(error);
      return null;
    } else {
      return data;
    }
  } else {
    const { data, error } = await supabase.rpc("nearby_posts", {
      lat: lat,
      long: long,
      allowable_dist: distance,
    });

    if (error) {
      console.log(error);
      return null;
    } else {
      console.log("Nearby Posts: ", data);
      return data;
    }
  }
}

/**
 * Use this to get the individual distances for each post
 * @param {UUID} post should hold the post's id
 * @param {float} lat
 * @param {float} long
 * @returns Object containing {distance, lat (of poster), long (of poster), post.id}
 */
export async function GetPostDistance(post_id, lat, long) {
  const { data, error } = await supabase.rpc("get_distance", {
    lat: lat,
    long: long,
    post_id: post_id,
  });

  if (error) {
    console.log(error);
    return null;
  } else {
    return data;
  }
}

export async function GetPostData(post_id, searchdata = null) {
  const query = supabase
    .from("posts")
    .select(
      "id, stock_number, description, currencytype, price, year, vehicle (id, name), parts (id, part_name), imagePath, user_id, userdata (city, state, country, username), location, favorites (id)"
    )
    .eq("id", post_id);

  if (searchdata != null) {
    if (searchdata.year != "" || searchdata.year === undefined) {
      console.log("added Year Filter");
      query.eq("year", searchdata.year);
    }
    if (searchdata.make != "" || searchdata.make === undefined) {
      console.log("added Vehicle Filter");
      query.eq("vehicle_id", searchdata.make);
    }
    if (searchdata.part != "" || searchdata.part === undefined) {
      console.log("added Part Filter");
      query.eq("part", searchdata.part);
    }
  }
  query.single();

  const { data, error } = await query;

  if (error) {
    console.log(error);
    return null;
  } else {
    return data;
  }
}

//#endregion

export async function DeletePost(post_id) {
  const { data, error } = await supabase
    .from("posts")
    .delete()
    .eq("id", post_id);

  if (error) {
    console.log(error);
    return false;
  } else {
    return true;
  }
}

//#region Images
export async function GetImages(path) {
  if (path == null || path == undefined) {
    return null;
  }

  let bucket = path.includes("reports") ? "reports" : "posts";

  //Remove the / from the end of the path
  if (path.charAt(path.length - 1) === "/") {
    path = path.slice(0, -1);
  }

  //Parse the path to get the folder name
  const { data, error } = await supabase.storage
    .from(bucket)
    .list(path, { limit: 4 });

  if (error) {
    Alert("Error getting images: ", error);
    return null;
  } else {
    data.map((image) => {
      image.url = path + "/" + image.name;
      // console.log(image.url);
    });

    //For each image, get a signed URL if the path is reports or a regular URL if the path is posts
    if (path.includes("reports")) {
      if (data == null || data == undefined || data.length == 0) {
        return null;
      }
      data.image = await LoadSingedURLs(data);
      return data;
    } else {
      data.map(async (image) => {
        image.image = await LoadPublicUrl(image.url, "posts");
      });
      // console.log("Posts: ", data);
      return data;
    }
  }
}
export async function LoadSingedURLs(data) {
  let names = [];
  data.map((image) => {
    names.push(image.url);
  });

  return await LoadPrivateUrls(names);
}
export async function LoadPublicUrl(imagePath, bucket) {
  const { data } = supabase.storage.from(bucket).getPublicUrl(imagePath);
  return data.publicUrl;
}
const LoadPrivateUrls = async (names) => {
  const { data, error } = await supabase.storage
    .from("reports")
    .createSignedUrls([...names], 60000);

  if (error) {
    console.log(error);
    return null;
  } else {
    return data;
  }
};
export const GenerateUUID = () => {
  return "xxxxxxxx-xxxx".replace(/[x]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
//#endregion

//#region Messages
export async function GetActiveContacts(user_id) {
  console.log("User ID: ", user_id);
  const { data, error } = await supabase.rpc("get_users_i_spoke_with", {
    input_uuid: user_id,
  });
  if (error) {
    console.log(error);
    return null;
  } else {
    data.map((contact, index) => {
      if (contact.user_id === user_id) {
        data.splice(index, 1);
      }
    });
    return data;
  }
}

export async function GetContacts(user_id) {
  const { data, error } = await supabase
    .from("messages")
    .select("user_id, username, imagePath")
    .neq("user_id", user_id);
  if (error) {
    console.log(error);
    return null;
  } else {
    return data;
  }
}

export async function GetMessages(user_id, receiver_id) {
  if (receiver_id == user_id) {
    console.log(
      "GetMessages user is attempting to communicate with themselves."
    );
    return null;
  }
  if (
    user_id === null ||
    user_id === undefined ||
    receiver_id === null ||
    receiver_id === undefined
  ) {
    return null;
  }
  console.log("GM2");
  console.log("SENDER ID: ", user_id);
  console.log("Receiver ID: ", receiver_id);
  const { data, error } = await supabase.rpc("get_messages_2", {
    sender: user_id,
    receiver: receiver_id,
  });
  if (error) {
    console.log(error);
    return null;
  } else {
    console.log("Messages: ", data);
    return data;
  }
}
//#endregion

//#region Reports
export async function LoadReports() {
  const { data, error } = await supabase
    .from("reports")
    .select(
      "id, posts (id, vehicle (name), year, parts (part_name), stock_number, description, price, user_id, location, imagePath), reason, description, email"
    )
    .eq("resolved", false);
  if (error) {
    console.log(error);
    return null;
  } else {
    return data;
  }
}
//#endregion

//#region Favorites
export async function AddtoFavorites(post, user_id) {
  const { data, error } = await supabase
    .from("favorites")
    .insert([{ post: post.id, user: user_id }]);
  if (error) {
    console.log(error);
    return null;
  } else {
    return data;
  }
}

export async function RemoveFromFavorites(id) {
  const { data, error } = await supabase
    .from("favorites")
    .delete()
    .eq("id", id);
  if (error) {
    console.log(error);
    return null;
  } else {
    return data;
  }
}

export async function CheckFavorite(post_id, user_id) {
  const { data, error } = await supabase
    .from("favorites")
    .select("id")
    .eq("post", post_id)
    .eq("user", user_id);

  if (error) {
    console.error("Error checking favorite:", error);
    return null;
  }

  // If data is not empty, it means the post is already favorited by the user
  return data.length > 0 ? data[0].id : false;
}
//#endregion

//#region Registration
export const RegisterNewUser = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({
    email: email,
    password: password,
    options: {
      emailRedirectTo: `sing-up/verify?email=${email}`,
    },
  });
  if (error) {
    alert(error.message);
  } else {
    return data;
  }
};

export async function getUserData(user) {
  try {
    const { data, error } = await supabase
      .from("userdata")
      .select("*")
      .eq("user_id", user.id)
      .single();

    if (error) {
      console.log(error.message);
      return null;
    } else if (data === null || data === undefined) return null;
    else return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
//#endregion

//#region Admin
export async function GetRegion(user_id) {
  try {
    fetch("/get-region", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: user_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        return data;
      });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function isAdmin(user_id) {
  try {
    const response = await fetch("/is-admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: user_id }),
    });
    const data = await response.json();
    console.log("Success:", data);

    return data.isAdmin;
  } catch (error) {
    console.log(error);
    return false;
  }
}
//#endregion
