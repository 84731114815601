import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { colorPalette } from '../../styles';

const ImageModal = ({ show, onHide, imageUrl }) => {
    useEffect(() => {
        const style = document.head.appendChild(document.createElement("style"));
        style.innerHTML = `.modal-backdrop.show {opacity: 0.3;}`;

        return () => document.head.removeChild(style); // Cleanup the style element when the component unmounts
    }, []);

    return (
        <Modal show={show} onHide={onHide} size="lg" centered >
            <Modal.Header closeButton>
                <Modal.Title style={{ color: colorPalette.primary_blue }}>Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imageUrl && <img src={imageUrl} style={{ width: '100%' }} alt="Preview" />}
            </Modal.Body>
        </Modal>
    );
};

export default ImageModal;