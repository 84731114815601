export const colorPalette = {
    primary_black: '#000000',
    primary_white: '#FCFCFC',
    primary_blue: '#005377',
    accent_blue: '#00A7E1',
    accent_cream: '#FAF0CA',
    accent_grey: '#7E7E7E',
    accent_white: '#EEEEEE',
    highlight_blue: '#CADDFA',
    action_orange: '#FFA500',
    action_orange_highlighted: '#F59F00',
    action_blue: '#005377',
    action_blue_highlighted: '#227599',
};

export const ButtonStyles = {
    saveButton: {
        backgroundColor: colorPalette.action_blue,
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        color: colorPalette.primary_white,
        borderRadius: '5px',

    },

    submitButton: {
        backgroundColor: colorPalette.action_blue,
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        color: colorPalette.primary_white,
        borderRadius: '5px',
        width: '100%',
    },

    viewPostButton: {
        backgroundColor: colorPalette.action_blue,
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        color: colorPalette.primary_white,
        borderRadius: '5px',
        width: '150px',
        margin: '5px',
    },
    deletePostButton: {
        backgroundColor: 'red',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        color: colorPalette.primary_white,
        borderRadius: '5px',
        margin: '5px',

        width: '150px'

    },
    IgnoreReportButton: {
        backgroundColor: 'green',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        color: colorPalette.primary_white,
        borderRadius: '5px',
        margin: '5px',

        width: '150px'
    },

    optionSelectedButton: {
        backgroundColor: colorPalette.highlight_blue,
        padding: '10px 20px',
        cursor: 'pointer',
        color: colorPalette.primary_black,
        borderRadius: '5px',
        width: '100%',
        border: '1px solid',
        borderColor: colorPalette.primary_black,
        marginBottom: '5px',
        textAlign: 'left',
    },
    optionNotSelectedButton: {
        backgroundColor: colorPalette.primary_white,
        padding: '10px 20px',
        cursor: 'pointer',
        color: colorPalette.primary_black,
        borderRadius: '5px',
        width: '100%',
        border: '1px solid',
        borderColor: colorPalette.primary_black,
        marginBottom: '5px',
        textAlign: 'left',

    },
};

export const FormStyles = {
    formContainer: {
        display: 'flex',           // Enable flexbox
        justifyContent: 'center',  // Center content horizontally
        flexDirection: 'column',   // Arrange children in a column
        padding: '20px',
        maxWidth: '450px',        // Set maximum width
        margin: 'auto',            // Center content horizontally
        borderRadius: '5px',

        backgroundColor: colorPalette.primary_white,
        //if wanted to apply exact width, remove align items and add maxWidth: 600, margin auto
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        marginBottom: '20px',
    },
    textInput: {
        padding: 10,
        borderRadius: 5,
        border: '1px solid #ccc',
        marginBottom: 5,
        width: '100%',

    },
    leftAlignedLabel: {
        textAlign: 'left',
        width: '100%', // Ensures label takes full width of its container
        marginBottom: '5px', // Space between label and input box
    },
    headingText: {
        color: "var(--primary_blue)",
    },
}

export const PostPageStyles = {
    formContainer: {
        display: 'flex',           // Enable flexbox
        justifyContent: 'center',  // Center content horizontally
        flexDirection: 'column',   // Arrange children in a column
        padding: '20px',
        maxWidth: '800px',        // Set maximum width
        margin: 'auto',            // Center content horizontally
        borderRadius: '5px',

        backgroundColor: colorPalette.primary_white,
        //if wanted to apply exact width, remove align items and add maxWidth: 600, margin auto
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        marginBottom: '20px',
    },
    textInput: {
        padding: 10,
        borderRadius: 5,
        border: '1px solid #ccc',
        marginBottom: 5,
        width: '100%',

    },
    leftAlignedLabel: {
        textAlign: 'left',
        width: '100%', // Ensures label takes full width of its container
        margin: '5px', // Space between label and input box
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'var(--primary_blue)'
    },
    headingText: {
        color: "var(--primary_blue)",
    },
    dropdown: {
        padding: '10px',
        border: '2px solid #ddd',
        borderRadius: '4px',
        backgroundColor: 'white',
        fontSize: '16px',

    }
}

export const ImageUploadStyles = {

    leftAlignedLabel: {
        textAlign: 'left',
        width: '100%', // Ensures label takes full width of its container
        marginBottom: '5px', // Space between label and input box
    },
    headingText: {
        color: "var(--primary_blue)",
    },
}

export const SearchBarStyles = {
    dropdownHeader: {
        padding: '20px',
        width: '100%',
    },
    searchForm: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: '10px',
        alignItems: 'center',

    },
    dropdown: {
        padding: '10px',
        border: '2px solid #ddd',
        borderRadius: '4px',
        backgroundColor: 'white',
        fontSize: '16px',

    }
};

export const WebsiteHeaderStyles = {
    h1: {
        padding: "10px",
    },
    h1Text: {
        fontFamily: 'Impact, sans-serif',
        fontSize: '48px',
        display: 'inline',
    },
};

export const PostTableStyles = {
    cardContainer: {
        backgroundColor: colorPalette.primary_white,
        cursor: 'pointer',
        border: '2px solid',
        borderColor: colorPalette.primary_blue,
        position: 'relative',
    },
    cardHover: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.5)',
    }
};

export const MessagesStyles = {
    chatContainer: {
        backgroundColor: colorPalette.accent_white,
        border: '2px solid',
        borderColor: colorPalette.primary_blue,
        flexDirection: 'column',
        overflowY: 'auto',
        height: '60vh',
        display: 'flex',
        borderRadius: 10,
        marginBottom: '10px',
    },
    chatInput: {
        padding: 10,
        borderRadius: 5,
        border: '1px solid #ccc',
        marginBottom: 5,
        width: '100%',
        resize: 'none',
        overflow: 'hidden',
    },

};

export const AdminDashboardStyles = {
    linkRow: {
        display: 'flex',
        padding: 5,
        margin: 5,
        border: '2px solid red',
        borderRadius: '10px',
        alignItems: 'center',
        color: 'black',
        textDecorationLine: 'none',
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%',
        alignItems: 'center',
        overflow: 'collapse',
    },
    headingText: {
        color: colorPalette.primary_blue,
    },
    dropdownHeader: {
        padding: '20px',
        width: '100%',
    },
    searchForm: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: '10px',
        alignItems: 'center',

    },
    dropdown: {
        padding: '10px',
        border: '2px solid #ddd',
        borderRadius: '4px',
        fontSize: '16px',
        marginBottom: '10px',
        width: '100%',

    },
    formContainer: {
        display: 'flex',           // Enable flexbox
        justifyContent: 'center',  // Center content horizontally
        flexDirection: 'column',   // Arrange children in a column
        padding: '20px',
        maxWidth: '800px',        // Set maximum width
        margin: 'auto',            // Center content horizontally
        borderRadius: '5px',

        backgroundColor: colorPalette.primary_white,
        //if wanted to apply exact width, remove align items and add maxWidth: 600, margin auto
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        marginBottom: '20px',
    },
    textInput: {
        padding: 10,
        borderRadius: 5,
        border: '1px solid #ccc',
        marginBottom: 5,
        width: '100%',

    },
    leftAlignedLabel: {
        textAlign: 'left',
        width: '100%', // Ensures label takes full width of its container
        marginBottom: '5px', // Space between label and input box
    },
};