import { useState, useRef, useEffect } from "react";
import { supabase } from "../../lib/api"
import { Form, Button, Dropdown } from "react-bootstrap";
import { useUser } from "@supabase/auth-helpers-react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import AddressLookup from "../WIP/AddressLookup";
import { ButtonStyles, FormStyles as styles } from "../../styles";

/**
 * TODO: Display this page when the user registers to the website and loads in. If the user is signed in, perform a test to see if they've filled out this information. This check should also be performed prior to Posting content.
 * @returns 
 */
const AccountSettings = () => {
    const user = useUser();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        async function getUserData() {
            try {
                const { data, error } = await supabase.from('userdata').select('*').eq('user_id', user.id).single();
                console.log("User Object: ", data);

                if (error) {
                    console.log(error.message);
                    return null;
                }
                else if (data === null || data === undefined)
                    return null;
                else
                    return data;
            }
            catch (error) {
                console.log(error);
                return null;
            }
        }

        if (user !== null) {

            //Generate an object that can be used to fill in the form
            getUserData()
                .then((data) => {
                    if (data === null) {
                        return;
                    }
                    else if (data !== null) {
                        setUserData(data);
                        const userData = data;
                        setUsername(userData.username);
                        setFirstName(userData.first_name);
                        setLastName(userData.last_name);
                        setAddress(userData.street);
                        setCity(userData.city);
                        setState(userData.state);
                        setZipCode(userData.zip);
                        setCountry(userData.country);
                    }
                });
        }
    }, [user]);

    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [street, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('Select Country');
    const [latLng, setLatLng] = useState(null);

    const handleUpdate = (results, latlng) => {
        if (results && latlng) {
            const addressParse = fillInAddress(results[0]);

            setAddress(addressParse.address1);
            setCity(addressParse.city);
            setState(addressParse.province);
            setZipCode(addressParse.postalcode);
            setCountry(addressParse.country);
            setLatLng(latlng);
        }
    }

    function fillInAddress(address) {
        let address1 = "";
        let address2 = "";
        let postcode = "";
        let city = "";
        let province = "";
        let country = "";

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of address.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }

                case "route": {
                    address1 += component.short_name;
                    break;
                }

                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }

                case "postal_code_suffix": {
                    postcode = `${postcode}-${component.long_name}`;
                    break;
                }
                case "locality":
                    city = component.long_name;
                    break;
                case "administrative_area_level_1": {
                    province = component.short_name;
                    break;
                }
                case "country":
                    country = component.long_name;
                    break;
            }
        }
        return {
            address1: address1,
            address2: address2,
            postalcode: postcode,
            city: city,
            province: province,
            country: country,
            formatted_address: address.formatted_address,
        };
    }

    const ValidateFields = async () => {
        //Step 1: Validate the inputs
        if (username === "" || firstName === "" || lastName === "" || street === "" || city === "" || state === "" || zipCode === "" || country === "") {
            alert("Please fill in all the fields!");
            return false;
        }


        //Step 2: Ensure correct formatting of the inputs
        if (username.length > 20) {
            alert("Username cannot be longer than 20 characters!");
            return false;
        }

        if (username.includes(" ")) {
            alert("Username cannot contain spaces!");
            return false;
        }
        if (firstName.length > 20) {
            alert("First name cannot be longer than 20 characters!");
            return false;
        }
        if (lastName.length > 20) {
            alert("Last name cannot be longer than 20 characters!");
            return false;
        }
        return true;
    }

    //Check if this username exists in the database via an API call
    const usernameExists = async () => {
        try {
            const { data, error } = await supabase.from('userdata').select('username').eq('username', username).single();
            if (error) {
                console.log(error.message);
                return false;
            }
            else if (data === null || data === undefined) {
                return false;
            }
            else {
                if (data.username === userData.username) return false;
                else return true;
            }
        }
        catch (error) {
            console.log(error.message);
            return false;
        }
    }


    const DetermineChanges = () => {
        let updateObject = {};
        //Step 1: Check if any of the fields have changed if they have, append them to the update packet
        if (username !== userData.username) updateObject.username = username;
        if (firstName !== userData.first_name) updateObject.first_name = firstName;
        if (lastName !== userData.last_name) updateObject.last_name = lastName;
        if (street !== userData.address) updateObject.street = street;
        if (city !== userData.city) updateObject.city = city;
        if (state !== userData.state) updateObject.state = state;
        if (zipCode !== userData.zip_code) updateObject.zip = zipCode;
        if (country !== userData.country) updateObject.country = country;

        return updateObject;
    };

    const SaveChanges = async () => {
        //Step 1: Validate the fields
        if (!ValidateFields())
            return;

        if (await usernameExists()) {
            alert("Username already exists!");
            return;
        }

        console.log("Fields validated!");
        //Step 2: Check if the user already has data in the database
        if (userData !== null) {
            console.log("Updating existing data!");
            const updatePacket = DetermineChanges();
            console.log("Update Packet: ", updatePacket);
            try {

                if (updatePacket?.address || updatePacket.city || updatePacket.state || updatePacket.country || updatePacket.zipCode) {
                    const results = await geocodeByAddress(`${updatePacket.address}, ${updatePacket.city}, ${updatePacket.state}, ${updatePacket.zipCode}, ${updatePacket.country}`);
                    const newLatLng = await getLatLng(results[0]);
                    updatePacket.location = `POINT(${newLatLng.lat} ${newLatLng.lng})`;
                    console.log("Lat Lng: ", newLatLng);
                }

                const { data, error } = await supabase.from('userdata').update(updatePacket).eq('user_id', user.id);
                if (error) {
                    console.log(error.message);
                    return;
                }
                else {
                    alert("Successfully updated user data!");
                }
            }
            catch (error) {
                console.log(error.message);
            }
        }
        else {
            console.log("Inserting new data!");
            //Insert the data into the database
            try {
                let tempLatLng = latLng;
                if (tempLatLng === null || tempLatLng === undefined) {
                    const results = await geocodeByAddress(`${street}, ${city}, ${state}, ${zipCode}, ${country}`);
                    tempLatLng = await getLatLng(results[0]);
                    console.log("latLng: ", tempLatLng);
                }

                console.log("Values: ", tempLatLng);
                let location = `POINT(${tempLatLng.lat} ${tempLatLng.lng})`;
                console.log("Location: ", location);
                const { data, error } = await supabase.from('userdata').insert([
                    {
                        user_id: user.id,
                        username: username,
                        first_name: firstName,
                        last_name: lastName,
                        street: street,
                        city: city,
                        state: state,
                        zip: zipCode,
                        country: country,
                        location: location
                    }
                ]);
                if (error) {
                    console.log(error.message);
                    return;
                }
                else {
                    console.log(data);
                    alert("Successfully updated user data!");
                }
            }
            catch (error) {
                console.log(error.message);
            }
        }
    };

    return (

        <Form style={styles.formContainer}>
            <h1 style={styles.headingText}>Account Settings</h1>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{user?.email}</Form.Label>
            </Form.Group>

            <Form.Group className="mb-3" controlId="UserInformation">
                <div className="row">
                    <div className="col">
                        <Form.Label style={styles.leftAlignedLabel}>Username:</Form.Label>
                        <Form.Control style={styles.textInput} type="text" value={username} placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6"> {/* Half width for medium to large screens */}
                        <Form.Label style={styles.leftAlignedLabel}>First Name:</Form.Label>
                        <Form.Control style={styles.textInput} type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>

                    <div className="col-sm-6"> {/* Half width for medium to large screens */}
                        <Form.Label style={styles.leftAlignedLabel}>Last Name:</Form.Label>
                        <Form.Control style={styles.textInput} type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                </div>


                <div className="row">
                    <div className="col">

                        <Form.Label style={styles.leftAlignedLabel}>Search Address:</Form.Label>
                        <AddressLookup onSelect={handleUpdate} />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Form.Label style={styles.leftAlignedLabel}>Street Address:</Form.Label>

                        <Form.Control style={styles.textInput} type="text" value={street} placeholder="Street" onChange={(e) => setAddress(e.target.value)} />
                    </div>

                </div>

                <div className="row">
                    <div className="col-sm-6"> {/* Half width for medium to large screens */}
                        <Form.Label style={styles.leftAlignedLabel}>City:</Form.Label>

                        <Form.Control
                            style={styles.textInput}
                            type="text"
                            value={city}
                            placeholder="City"
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className="col-sm-6"> {/* Half width for medium to large screens */}
                        <Form.Label style={styles.leftAlignedLabel}>Postal Code:</Form.Label>

                        <Form.Control
                            style={styles.textInput}
                            type="text"
                            value={zipCode}
                            placeholder="Zip"
                            onChange={(e) => setZipCode(e.target.value)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <Form.Label style={styles.leftAlignedLabel}>State/Province:</Form.Label>

                        <Form.Control
                            style={styles.textInput}
                            type="text"
                            value={state}
                            placeholder="State"
                            onChange={(e) => setState(e.target.value)}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Form.Label style={styles.leftAlignedLabel}>Country:</Form.Label>

                        <Form.Control
                            style={styles.textInput}
                            type="text"
                            value={country}
                            placeholder="Country"
                            onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>
                </div>
            </Form.Group>

            <button type="button" style={ButtonStyles.submitButton} onClick={SaveChanges}>
                Save Changes
            </button>
        </Form>

    );
};

export default AccountSettings;