import React from 'react';
import { Container, Col, Card, Accordion, Button } from 'react-bootstrap';
import { FormStyles as styles } from "../../styles";

const FAQs = () => {
    return (
        <Container>
            <Col md={12} style={styles.formContainer}>
                <h1 style={styles.headingText}>FAQs</h1>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>How do I contact a seller?</Accordion.Header>
                        <Accordion.Body style={{ textAlign: 'left' }}>
                            <ul>
                                <li>Visit the seller's profile page or the listing you are interested in.</li>
                                <li>Click on the "Contact Seller" button usually found below the product description or at the top of the seller's profile.</li>
                                <li>Fill out the contact form with your message and contact details.</li>
                                <li>Submit the form, and wait for the seller to respond to your inquiry via email or the platform's messaging system.</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>How do I favorite a post?</Accordion.Header>
                        <Accordion.Body style={{ textAlign: 'left' }}>
                            <ul>
                                <li>Navigate to the post you wish to favorite.</li>
                                <li>Look for a heart icon or a "Favorite" button, typically located near the post title or at the bottom of the post.</li>
                                <li>Click on the heart icon or "Favorite" button. The post will then be added to your list of favorite items.</li>
                                <li>To view your favorited posts, go to your profile and find the Favorites section or tab.</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
        </Container>
    );
};

export default FAQs;