import { useEffect, useState, createRef } from 'react'
import { GetImages, GetPostData, DeletePost } from '../Getters.js';
import { Container, Row, Col, Carousel, Button, Card } from 'react-bootstrap';
import { PostPageStyles as styles, ButtonStyles } from '../../styles.js';
import placeholderImage from '../../assets/No_Image_Placeholder.png';
import ImageModal from '../WIP/ImageModal.js';
import USIcon from '../../assets/united-states.png';
import CadIcon from '../../assets/canada.png';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useUser } from '@supabase/auth-helpers-react';


const ViewPost = () => {
    let { id } = useParams(); // This hooks extracts `id` from the URL
    let navigate = useNavigate();
    const user = useUser();
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const openModalWithImage = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    //Get the Post information from the database
    const [post, setPost] = useState(null);
    const [images, setImages] = useState([]);
    console.log("Post ID: ", id);

    useEffect(() => {
        async function GetPost() {
            console.log("Post ID: ", id);
            const data = await GetPostData(id);
            if (data == null) {
                console.log("No Post Available");
                return;
            }
            else {
                console.log("Post: ", data);
                setPost(data);
            }
        }
        GetPost();
    }, []);

    useEffect(() => {

        async function LoadPictures() {
            const images = await GetImages(post.user_id + "/" + post.id);
            if (images == null) {
                console.log("No Images Available");
                return;
            }
            else {
                console.log("Images: ", images);
                setImages(images);
            }

        }
        if (post != null && post != undefined)
            LoadPictures();
    }, [post]);

    if (post == null || post == undefined) {
        return (
            <></>
        );
    }

    const GoToMessageSeller = () => {
        navigate(`/messages/${post.userdata.username}`);
    }

    return (
        <Container>
            <Row className="my-4">
                <Col>
                    <h1 style={styles.headingText}>{post.year} {post.vehicle.name} - {post.parts.part_name} - {post.stock_number}</h1>
                </Col>
            </Row>
            <ImageModal show={showModal} onHide={closeModal} imageUrl={selectedImage} />

            <Row>
                <Col md={8}>
                    <Carousel fade>
                        {images.length > 0 ? (
                            images.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={image.image}
                                        alt={`${post.vehicle.name} image ${index + 1}`}
                                        style={{ height: '500px', objectFit: 'cover', cursor: 'pointer' }}
                                        onClick={() => openModalWithImage(image.image)}
                                    />
                                </Carousel.Item>
                            ))
                        ) : (
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={placeholderImage}
                                    alt="Placeholder"
                                    style={{ height: '500px', objectFit: 'cover' }}
                                />
                            </Carousel.Item>
                        )}
                    </Carousel>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title style={styles.headingText}>Details</Card.Title>
                            <Card.Text>{post.description}</Card.Text>
                            <Card.Text style={{ color: 'green', fontWeight: 'bold' }}>
                                ${post.price}
                                {post.currencytype === 'CAD' ? (
                                    <img src={CadIcon} alt="Canada Flag" style={{ width: '24px', marginLeft: '10px' }} />
                                ) : (
                                    <img src={USIcon} alt="USA Flag" style={{ width: '24px', marginLeft: '10px' }} />
                                )}
                            </Card.Text>
                            <Card.Text>
                                Seller: <Link to={`/user-profile/${post.user_id}`}>{post.userdata.username}</Link>
                            </Card.Text>
                            <button type="button" style={ButtonStyles.submitButton} onClick={GoToMessageSeller}>Contact Seller</button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="my-4">
                <Col>
                {post != null ? (
                    <div>
                        <h1 style={styles.headingText}>Location: {post.userdata.city}, {post.userdata.state}, {post.userdata.country}</h1>
                        <iframe 
                        style={{border: 0, width: '100%', height: '400px', borderRadius: '10px'}} 
                        loading={'lazy'} 
                        src={`https://www.google.com/maps/embed/v1/place?q=${post.userdata.city},+${post.userdata.state},+${post.userdata.country}&key=AIzaSyALxawe8S5nRkyBq2LXS4dU1QpdUtMB1Aw`}>
                        </iframe>
                    </div>
                    ) : (
                    <h1 style={styles.headingText}>Location: Not Available</h1>)}
                </Col>
            </Row>
            {(user != null && user.id == post.user_id) && (
                <Row className="my-4">
                    <Col>
                        <Button variant="primary" onClick={async () => {
                            const value = await DeletePost(post.id)
                            if(value == true){
                                alert("Post Deleted")
                                setTimeout(() => {
                                    navigate('/profile')
                                }, 1000)    
                            }
                            else{
                                alert("Error Deleting Post")
                            }
                        }} style={{backgroundColor: 'red', borderColor: 'red'}}>Delete this Post</Button>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default ViewPost;