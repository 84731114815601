import chatIcon from "../../assets/messageSquare.png";
import editIcon from "../../assets/edit.png";
import reportIcon from "../../assets/alert-triangle.png";
import favoriteIcon from "../../assets/star.png";
import CadIcon from "../../assets/canada.png";
import USIcon from "../../assets/united-states.png";
import { AddtoFavorites, RemoveFromFavorites, CheckFavorite } from "../Getters";
import { Button, Col, Row, Card } from "react-bootstrap";
import { useUser } from "@supabase/auth-helpers-react";
import { useState, useEffect } from "react";
import placeholderImage from "../../assets/No_Image_Placeholder.png";
import { colorPalette, PostTableStyles as styles } from "../../styles";
import ImageModal from "./ImageModal";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function PostTable({ posts }) {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const openModalWithImage = (e, imageUrl) => {
    e.stopPropagation();
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [sortedPosts, setSortedPosts] = useState(posts);
  const [sortCriteria, setSortCriteria] = useState("price"); // 'price' or 'year'
  const [sortOrder, setSortOrder] = useState("ascending");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleMouseEnter = (id) => {
    setHoveredCardId(id);
  };

  const handleMouseLeave = () => {
    setHoveredCardId(null);
  };

  useEffect(() => {
    sortPosts(sortCriteria, sortOrder);
  }, [posts, sortCriteria, sortOrder]);

  const toggleSort = (criteria) => {
    const newOrder =
      sortCriteria === criteria && sortOrder === "ascending"
        ? "descending"
        : "ascending";
    setSortCriteria(criteria);
    setSortOrder(newOrder);
    sortPosts(criteria, newOrder);
  };

  const sortPosts = (criteria, order) => {
    const sortedPosts = [...posts].sort((a, b) => {
      if (criteria === "price" || criteria === "year") {
        return order === "ascending"
          ? a[criteria] - b[criteria]
          : b[criteria] - a[criteria];
      }
      // Implement other sorting logic if needed, for example, string comparison
      return 0;
    });
    setSortedPosts(sortedPosts);
  };

  const location = useLocation();
  const context = location.pathname.split("/").pop();
  const user = useUser();

  const Favorite = async (post, user_id) => {
    setIsButtonDisabled(true);
    try {
      const favoriteId = await CheckFavorite(post.id, user_id);
      if (favoriteId) {
        // Remove from favorites
        const result = await RemoveFromFavorites(favoriteId);
      } else {
        // Add to favorites
        const result = await AddtoFavorites({ id: post.id }, user_id);
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  //If the context in the url is my-posts provide an Edit icon instead of a chat icon
  const handleContext = (post) => {
    // Determine the path based on conditions
    let path;
    if (user == null || user === undefined) {
      path = "/auth";
    } else if (context === "my-posts" || post.user_id === user?.id) {
      path = `/edit/${post.id}`;
    } else {
      path = `/messages/${post.username}`;
    }

    // Handler to stop event propagation
    const handleClick = (e) => {
      e.stopPropagation();
      // Additional logic if needed
    };

    // Return a Link component with the onClick handler
    return (
      <Link
        to={path}
        onClick={handleClick}
        style={{
          alignSelf: "center",
          backgroundColor: colorPalette.accent_grey,
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        <img
          src={
            context === "my-posts" || post.user_id === user?.id
              ? editIcon
              : chatIcon
          }
          alt={
            context === "my-posts" || post.user_id === user?.id
              ? "edit"
              : "chat"
          }
        />
      </Link>
    );
  };

  const handleSort = (sortOption) => {
    console.log("Sorting by: ", sortOption);
    //Sort the posts by the sortOption
    switch (sortOption) {
      case "year":
        posts.sort((a, b) => (a.year > b.year ? 1 : -1));
        break;
      case "make":
        posts.sort((a, b) => (a.vehicle_name > b.vehicle_name ? 1 : -1));
        break;
      case "part":
        posts.sort((a, b) => (a.part_name > b.part_name ? 1 : -1));
        break;
      case "distance":
        posts.sort((a, b) => (a.dist_meters > b.dist_meters ? 1 : -1));
        break;
      default:
        break;
    }
  };

  const GoToViewPost = (post_id) => navigate(`/view/${post_id}`);

  //RETURN NOTHING IF EMPTY
  if (posts == null || posts == undefined) {
    return <></>;
  }

  //#region Chevrons for Price and Year sorting
  const ChevronUp = () => (
    <svg
      viewBox="0 0 20 20"
      width="20"
      height="20"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path d="M5 12.5l5-5 5 5" />
    </svg>
  );

  const ChevronDown = () => (
    <svg
      viewBox="0 0 20 20"
      width="20"
      height="20"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path d="M5 7.5l5 5 5-5" />
    </svg>
  );
  //#endregion

  return (
    <div style={{ marginTop: 20, width: "100%" }}>
      <Row>
        <Col xs={12} className="mb-3">
          {/* Sorting options */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => toggleSort("price")}
            >
              <span>Price</span>
              {sortCriteria === "price" ? (
                sortOrder === "ascending" ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                )
              ) : (
                <ChevronDown style={{ visibility: "hidden" }} />
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
                cursor: "pointer",
              }}
              onClick={() => toggleSort("year")}
            >
              <span>Year</span>
              {sortCriteria === "year" ? (
                sortOrder === "ascending" ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                )
              ) : (
                <ChevronDown style={{ visibility: "hidden" }} />
              )}
            </div>
          </div>
        </Col>

        <ImageModal
          show={showModal}
          onHide={closeModal}
          imageUrl={selectedImage}
        />

        {sortedPosts.length > 0 ? (
          sortedPosts.map((post) => (
            <Col xs={12} className="mb-3" key={post.id}>
              <Card
                style={{
                  ...styles.cardContainer,
                  ...(hoveredCardId === post.id ? styles.cardHover : null),
                }}
                onMouseEnter={() => handleMouseEnter(post.id)}
                onMouseLeave={handleMouseLeave}
                onClick={() => GoToViewPost(post.id)}
              >
                {/* Conditionally render the layout based on the screen size */}
                <Card.Body>
                  <div
                    className="d-none d-lg-flex"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div style={{ flex: "1" }}>
                      <Card.Img
                        src={
                          post.images.length > 0
                            ? post.images[0].image
                            : placeholderImage
                        }
                        style={{
                          width: "150px",
                          height: "auto",
                          objectFit: "cover",
                        }}
                        onClick={(e) =>
                          openModalWithImage(
                            e,
                            post.images.length > 0
                              ? post.images[0].image
                              : placeholderImage
                          )
                        }
                      />
                    </div>
                    <div style={{ flex: "3", paddingLeft: "15px" }}>
                      <Card.Title
                        style={{
                          color: "green",
                          fontSize: "large",
                          fontWeight: "bold",
                        }}
                      >
                        ${post.price}
                      </Card.Title>
                      {/* Conditionally render flag beside the price */}
                      {post.currencytype === "CAD" ? (
                        <img
                          src={CadIcon}
                          alt="Canada Flag"
                          style={{ width: "24px" }}
                        />
                      ) : (
                        <img
                          src={USIcon}
                          alt="USA Flag"
                          style={{ width: "24px" }}
                        />
                      )}
                      <Card.Text style={{ marginBottom: "0" }}>
                        {post.year}
                        <br />
                        {post.vehicle_name}
                        <br />
                        {post.part_name}
                      </Card.Text>
                    </div>
                    {post?.stock_number && (
                      <div
                        style={{
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          textAlign: "left",
                          flex: 2,
                        }}
                      >
                        Part ID: {post?.stock_number}
                        <br />
                      </div>
                    )}
                    {/* Removed from Posts since the distance was not displaying correctly -- Est. Distance to Seller: {post.distance} miles<br /> */}

                    {/* Buttons column */}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      {/* Favorite Button */}
                      {user != null && post.user_id !== user.id && (
                        <button
                          disabled={isButtonDisabled}
                          style={{
                            marginBottom: "10px", // Space between buttons
                            backgroundColor:
                              post.favorites != null
                                ? "yellow"
                                : colorPalette.primary_blue,
                            border: "none",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // This stops the click event from propagating to parent elements
                            e.currentTarget.style.backgroundColor =
                              e.currentTarget.style.backgroundColor ===
                                "yellow" ||
                              e.currentTarget.style.backgroundColor === ""
                                ? colorPalette.primary_blue
                                : "yellow";
                            Favorite(post, user.id);
                          }}
                        >
                          <img
                            src={favoriteIcon}
                            alt="Favorite"
                            style={{ width: "24px" }}
                          />
                        </button>
                      )}

                      {/* Chat Button */}
                      {handleContext(post)}

                      {/* Report Button */}
                      <Link
                        to={`/report-post/${post.id}`}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          alignSelf: "center",
                          marginTop: "10px",
                          backgroundColor: "red",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          src={reportIcon}
                          alt="Report"
                          style={{ width: "24px" }}
                        />
                      </Link>
                    </div>
                  </div>
                  <div
                    className="d-none d-sm-flex d-lg-none"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {/* New layout for screens sm-lg */}

                    <div>
                      <Card.Img
                        src={
                          post.images.length > 0
                            ? post.images[0].image
                            : placeholderImage
                        }
                        style={{
                          width: "150px",
                          height: "auto",
                          objectFit: "cover",
                        }}
                        onClick={(e) =>
                          openModalWithImage(
                            e,
                            post.images.length > 0
                              ? post.images[0].image
                              : placeholderImage
                          )
                        }
                      />
                    </div>
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <Card.Title>
                        {post.year} <br />
                        {post.vehicle_name}
                      </Card.Title>
                      <Card.Text>
                        Part ID: {post.stock_number}
                        <br />
                      </Card.Text>
                      <Card.Title
                        style={{
                          color: "green",
                          fontSize: "large",
                          fontWeight: "bold",
                        }}
                      >
                        ${post.price}
                      </Card.Title>
                      {/* Conditionally render flag beside the price */}
                      {post.currencytype === "CAD" ? (
                        <img
                          src={CadIcon}
                          alt="Canada Flag"
                          style={{ width: "24px" }}
                        />
                      ) : (
                        <img
                          src={USIcon}
                          alt="USA Flag"
                          style={{ width: "24px" }}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "60px",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {/* Adjusted paddingRight to give space for the buttons */}
                      <Card.Text>
                        {post.description} <br />
                        {post.distance} miles away.
                      </Card.Text>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      {/* Favorite Button */}
                      {user != null && post.user_id !== user.id && (
                        <button
                          disabled={isButtonDisabled}
                          style={{
                            marginBottom: "10px", // Space between buttons
                            backgroundColor:
                              post.favorites != null
                                ? "yellow"
                                : colorPalette.primary_blue,
                            border: "none",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // This stops the click event from propagating to parent elements
                            e.currentTarget.style.backgroundColor =
                              e.currentTarget.style.backgroundColor ===
                                "yellow" ||
                              e.currentTarget.style.backgroundColor === ""
                                ? colorPalette.primary_blue
                                : "yellow";

                            Favorite(post, user.id);
                          }}
                        >
                          <img
                            src={favoriteIcon}
                            alt="Favorite"
                            style={{ width: "24px" }}
                          />
                        </button>
                      )}
                      {handleContext(post)}
                      <Link
                        to={`/report-post/${post.id}`}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          alignSelf: "center",
                          marginTop: "10px",
                          backgroundColor: "red",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          src={reportIcon}
                          alt="Report"
                          style={{ width: "24px" }}
                        />
                      </Link>
                    </div>
                  </div>

                  {/* Layout for xs screen */}
                  <div
                    className="d-block d-sm-none"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div className="row no-gutters align-items-center">
                      <div className="col-xs-12 col-sm-4">
                        <Card.Img
                          src={
                            post.images.length > 0
                              ? post.images[0].image
                              : placeholderImage
                          }
                          style={{
                            width: "auto",
                            height: "100px",
                            objectFit: "cover",
                            marginBottom: "10px",
                          }}
                          onClick={(e) =>
                            openModalWithImage(
                              e,
                              post.images.length > 0
                                ? post.images[0].image
                                : placeholderImage
                            )
                          }
                        />
                      </div>
                      <div
                        className="col-xs-12 col-sm-8"
                        style={{ textAlign: "left" }}
                      >
                        <Card.Title style={{ fontSize: "large" }}>
                          {post.year} - {post.vehicle_name}
                        </Card.Title>
                        <Card.Text>
                          Part ID: {post.stock_number}
                          <br />
                        </Card.Text>
                        <Card.Title
                          style={{
                            color: "green",
                            fontSize: "large",
                            fontWeight: "bold",
                          }}
                        >
                          ${post.price}
                        </Card.Title>
                        {/* Conditionally render flag beside the price */}
                        {post.currencytype === "CAD" ? (
                          <img
                            src={CadIcon}
                            alt="Canada Flag"
                            style={{ width: "24px" }}
                          />
                        ) : (
                          <img
                            src={USIcon}
                            alt="USA Flag"
                            style={{ width: "24px" }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="row no-gutters">
                      <div
                        className="col-12"
                        style={{ textAlign: "left", paddingRight: "60px" }}
                      >
                        <Card.Text>{post.description}</Card.Text>
                      </div>
                    </div>

                    <div
                      style={{
                        position: "static",

                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end", // This will move your items to the right
                      }}
                    >
                      {/* Favorite Button */}
                      {user != null && post.user_id !== user.id && (
                        <button
                          disabled={isButtonDisabled}
                          style={{
                            marginRight: "10px", // Space between buttons
                            backgroundColor:
                              post.favorites != null
                                ? "yellow"
                                : colorPalette.primary_blue,
                            border: "none",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // This stops the click event from propagating to parent elements
                            e.currentTarget.style.backgroundColor =
                              e.currentTarget.style.backgroundColor ===
                                "yellow" ||
                              e.currentTarget.style.backgroundColor === ""
                                ? colorPalette.primary_blue
                                : "yellow";

                            Favorite(post, user.id);
                          }}
                        >
                          <img
                            src={favoriteIcon}
                            alt="Favorite"
                            style={{ width: "24px" }}
                          />
                        </button>
                      )}
                      {handleContext(post)}
                      <Link
                        to={`/report-post/${post.id}`}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          alignSelf: "center",
                          marginLeft: "10px",
                          backgroundColor: "red",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          src={reportIcon}
                          alt="Report"
                          style={{ width: "24px" }}
                        />
                      </Link>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          // Need to make this contextual. If the user has not searched for anything, do not display this.
          <div style={{ textAlign: "center", padding: "20px" }}>
            <p>No posts found. Please check back later.</p>
          </div>
        )}
      </Row>
    </div>
  );
}
