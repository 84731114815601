import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { LoadReports } from "../Getters";
import { GetVehicleData, GetParts, GetImages, isAdmin } from "../Getters";
import { supabase } from "../../lib/api";
import { useUser } from '@supabase/auth-helpers-react';
import { AdminDashboardStyles as styles, ButtonStyles, colorPalette } from "../../styles";
import ImageModal from "../WIP/ImageModal";
import { useNavigate } from 'react-router-dom';


/**
 * This window should allow for the following operations to be performed:
 * 1. Adding, Editing or deleting an option to the Parts table or the Makes table
 * 2. Viewing all of the Reported Posts
 * 
 * The system should have a way to view all of the reported posts and allow the admin to delete the post. This delete operation should notify the user that their  post has been deleted and the reason why.
 */
const AdminDashboard = () => {
    let navigate = useNavigate();
    const user = useUser();

    async function checkAdmin(userId) {
        try {
            const isAdminUser = await isAdmin(userId);
            if (!isAdminUser) {
                console.log("Not an admin");
                navigate('/');
            } else {
                console.log("Admin");
            }
        } catch (error) {
            console.error("There was an error checking the admin status", error);
        }
    }

    useEffect(() => {
        // Only call checkAdmin if the user is signed in
        if (user) {
            checkAdmin(user.id);
        } else {
            // No user, so navigate to home or login
            navigate('/');
        }
    }, [user]);

    return (
        <div style={{ margin: "20px" }}>
            <h1 style={styles.headingText}>Admin Dashboard</h1>
            <ModifyList />
            <ReportedPosts />
        </div>
    );
};

//Displays a list of all of the reported posts
const ReportedPosts = () => {
    let navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const openModalWithImage = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const user = useUser();
    const [reports, setReports] = useState(null);
    const [images, setImages] = useState(null);
    //#region INITIALIZATION
    useEffect(() => {
        async function Initialize() {
            const data = await LoadReports();

            if (data == null) {
                return null;
            }
            //URl is not re-rendering due to the state change not being applied
            setReports(data);
        }
        Initialize();
    }, []);

    useEffect(() => {
        async function LoadImages() {
            if (reports.length == 0) return null;
            let promises = []
            reports.map((report) => {
                promises.push(GetImages(`reports/${report.id}`))
            })

            const temp = await Promise.all(promises);

            temp.map((set) => {
                if (set === null) { return; }
                console.log("SET: ", set);
                set.image.map((img) => {
                    console.log("IMG: ", img);
                })
            })

            setImages(temp);
        }
        if (reports != null || reports != undefined) {
            LoadImages();
        }
    }, [reports]);

    const GetReportImages = (imageSet) => {
        if (imageSet == null) {
            return null;
        }
        return (
            <div>
                {imageSet.image.map((img, index) => (
                    <img
                        src={img.signedUrl}
                        key={index}
                        style={{ width: "100px", height: "100px", margin: "5px", cursor: "pointer" }}
                        onClick={() => openModalWithImage(img.signedUrl)}
                    />
                ))}
            </div>
        );
    };

    const ViewPost = (post_id) => (event) => {
        event.preventDefault(); // Prevents the default action of the event
        event.stopPropagation(); // Stops the event from propagating up the DOM tree
        console.log("View Post: ", post_id);
        navigate(`/view/${post_id}`);
    };

    const GenerateReport = (report, index) => {
        if (report.posts == null) return null;
        return (
            <Card className="mb-3" key={report.id}>
                <Card.Body>
                    <Card.Title style={{ fontWeight: 'bold', color: colorPalette.primary_blue }}>{report.reason}</Card.Title>
                    <Row>
                        <Col xs={12} md={8}>
                            <p>{report.posts.id} : {report.posts.vehicle.name} - {report.posts.year}<br />{report.posts.parts.part_name}, {report.posts.stock_number}</p>
                            <p>{report.description}</p>
                            <p>{report.email}</p>
                        </Col>
                        {images != null && (
                            <Col xs={12} md={4}>
                                {GetReportImages(images[index])}
                            </Col>
                        )}
                        <ImageModal show={showModal} onHide={closeModal} imageUrl={selectedImage} />

                    </Row>
                    <button type="button" style={ButtonStyles.viewPostButton} onClick={ViewPost(report.posts.id)}>View Post</button>
                    <button type="button" style={ButtonStyles.deletePostButton} onClick={(event) => {
                        event.preventDefault(); // Prevents the default action of the event
                        event.stopPropagation(); // Stops the event from propagating up the DOM tree
                        DeletePost(report.posts.id, report.id)
                    }}>Delete Post</button>
                    <button type="button" style={ButtonStyles.IgnoreReportButton} onClick={(event) => {
                        event.preventDefault(); // Prevents the default action of the event
                        event.stopPropagation(); // Stops the event from propagating up the DOM tree
                        console.log("Ignore Report")
                    }}>Ignore Report</button>
                </Card.Body>
            </Card>
        )
    }

    const GetReports = () => {
        if (reports == null) {
            return null;
        }
        return (
            <div>
                {reports.map((report, index) => GenerateReport(report, index))}
            </div>
        );
    };
    //#endregion

    const DeletePost = async (post_id, report_id) => {
        if (user == null) return;
        //Call the server function
        fetch('/delete-post', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ post_id: post_id, user_id: user.id, report_id: report_id })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            });
    };

    return (
        <Form style={styles.formContainer}>

            <h3 style={styles.headingText}>Reported Posts</h3>
            {GetReports()}
        </Form>
    )
};

//Displays the 2 lists that the admin can modify
const ModifyList = () => {
    //#region States
    const [makes, setMakes] = useState([]);
    const [parts, setParts] = useState('');
    const [make, setMake] = useState('');
    const [part, setPart] = useState('');
    const [maker, setMaker] = useState('');
    const [parter, setParter] = useState('');
    //#endregion

    //#region HOOKS
    useEffect(() => {
        //Set the maker value to the make if make is not addNew or blank
        if (make != "addNew" && make != "") {
            setMaker(make.name);
        }
        else if (make == "addNew") {
            setMaker("");
        }
        if (part != "addNew" && part != "") {
            setParter(part.part_name);
        }
        else if (part == "addNew") {
            setParter("");
        }
    }, [make, part]);

    useEffect(() => {
        async function InitilaizeSearchOptions() {
            const all_makes = await GetVehicleData();
            const all_parts = await GetParts();

            setMakes(all_makes);
            setParts(all_parts);
        }

        InitilaizeSearchOptions();
    }, []);
    //#endregion

    //#region Update Functions
    const HandleMakeChange = async (context) => {
        switch (context) {
            case "add":
                //Add the new make to the database
                var { data, error } = await supabase.from('vehicle').insert([{ name: maker }])
                UpdatePage(error, 0);
                break;
            case "save":
                var { data, error } = await supabase.from('vehicle').update({ name: maker }).eq('id', make.id).single();
                UpdatePage(error, 0);
                break;
        }
    }

    async function UpdatePage(error, type) {
        if (error) {
            console.log(error);
        }
        else {
            if (type == 0) {
                setMaker('');
                setMake('');
                const all_makes = await GetVehicleData();
                setMakes(all_makes);
            }
            else if (type == 1) {
                setParter('');
                setPart('');
                const all_parts = await GetParts();
                setParts(all_parts);
            }
        }
    }

    const handleSelectionChange = (e, type) => {
        const value = e.target.value;
        console.log("Value: ", value);
        if (type === 'make') {
            if (value === 'addNew') {
                setMake('addNew');
                setMaker(''); // Resetting maker to empty for new entry
            } else {
                const selectedMake = makes.find(m => m.id == value);
                setMake(selectedMake || '');
                if (selectedMake) {
                    setMaker(selectedMake.name);
                }
            }
        } else if (type === 'part') {
            if (value === 'addNew') {
                setPart('addNew');
                setParter(''); // Resetting parter to empty for new entry
            } else {
                const selectedPart = parts.find(p => p.id == value);
                console.log("Selected Part: ", selectedPart);
                setPart(selectedPart || '');
                if (selectedPart) {
                    setParter(selectedPart.part_name);
                }
            }
        }
    };

    const HandlePartChange = async (context) => {
        switch (context) {
            case "add":
                //Add a new part to the options array
                var { data, error } = await supabase.from('parts').insert({ part_name: parter })
                UpdatePage(error, 1);
                break;
            case "save":
                var { data, error } = await supabase.from('parts').update({ part_name: parter }).eq('id', part.id).single();
                UpdatePage(error, 1);
                break;
        }
    }
    //#endregion

    return (
        <Form style={styles.formContainer}>
            <h3 style={styles.headingText}>Modify List</h3>
            {/* Create a dropdown with 2 options: parts, vehicles */}
            {/* Based on the choice, get the parts */}
            <select style={styles.dropdown} as="select" onChange={(e) => handleSelectionChange(e, 'make')}>
                <option key={'header'} value={''}>Makes</option>
                <option key={'addMake'} value={'addNew'}>{'Add New Selection'}</option>
                {makes.length > 0 && makes.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                ))}
            </select>
            {make != "" && (
                <div style={{ width: "100%", marginBottom: "10px", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: '5px', border: '1px solid #ccc', padding: "10px" }}>

                    <Form.Group className="mb-3">
                        <Form.Label style={styles.leftAlignedLabel}>Vehicle Name:</Form.Label>
                        <Form.Control style={styles.textInput} type="text" placeholder="Enter Make" value={maker} onChange={(e) => { setMaker(e.target.value) }} />
                        {make == "addNew" ? (
                            <button type="button" style={ButtonStyles.submitButton} onClick={(event) => {
                                event.preventDefault(); // Prevents the default action of the event
                                event.stopPropagation(); // Stops the event from propagating up the DOM tree
                                HandleMakeChange("add")
                            }}>Add New Option</button>
                        ) : (
                            <>
                                <button type="button" style={ButtonStyles.submitButton} onClick={(event) => {
                                    event.preventDefault(); // Prevents the default action of the event
                                    event.stopPropagation(); // Stops the event from propagating up the DOM tree
                                    HandleMakeChange("save")
                                }}>Save Change</button>
                            </>
                        )}
                    </Form.Group>
                </div>
            )}

            <select style={styles.dropdown} as="select" onChange={(e) => handleSelectionChange(e, 'part')}>
                <option key={'header_p'} value={''}>Parts</option>
                <option key={'addPart'} value={'addNew'}>{'Add New Part'}</option>
                {parts.length > 0 && parts.map((item) => (
                    <option key={item.id} value={item.id}>{item.part_name}</option>
                ))}
            </select>
            {part != "" && (
                <div style={{ width: "100%", marginBottom: "10px", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: '5px', border: '1px solid #ccc', padding: "10px" }}>

                    <Form.Group className="mb-3">
                        <Form.Label style={styles.leftAlignedLabel}>Part Name:</Form.Label>
                        <Form.Control style={styles.textInput} type="text" placeholder="Enter Part" value={parter} onChange={(e) => { setParter(e.target.value) }} />
                        {part == "addNew" ?
                            (
                                <button type="button" style={ButtonStyles.submitButton} onClick={(event) => {
                                    event.preventDefault(); // Prevents the default action of the event
                                    event.stopPropagation(); // Stops the event from propagating up the DOM tree
                                    HandlePartChange("add")
                                }}>Add New Option</button>
                            ) : (
                                <>
                                    <button type="button" style={ButtonStyles.submitButton} onClick={(event) => {
                                        event.preventDefault(); // Prevents the default action of the event
                                        event.stopPropagation(); // Stops the event from propagating up the DOM tree
                                        HandlePartChange("save")
                                    }}>Save Change</button>
                                </>
                            )}
                    </Form.Group>
                </div>
            )}
        </Form>
    )
};



export default AdminDashboard;

