import React, { useState , forwardRef, useImperativeHandle } from 'react';
import { Form } from 'react-bootstrap';
import { supabase } from '../../lib/api.js';
import { GenerateUUID } from '../Getters.js';
import { ImageUploadStyles as styles } from '../../styles.js';

const ImageUpload = forwardRef(({ onUpload }, ref) => {

  useImperativeHandle(ref, () => {
    return{
      UploadImages: (path) => {
        //Return a Promise Object to the parent component
        return new Promise((resolve, reject) => {
          if (files.length === 0) {
            console.log("break 3");
            resolve(true);
          }
          setUploading(true);
          let promises = [];

          for (let i = 0; i < files.length; i++) {
            console.log("Path: ", `${path}/${GenerateUUID()}`);
            promises.push(supabase.storage
              .from(path.includes('reports') ? 'reports' : 'posts')
              .upload(`${path}/${GenerateUUID()}`, files[i]));
          };

          Promise.all(promises)
            .then(async () => {
              setUploading(false);
              setUploads(null);
              setFiles([]);
              onUpload();
              resolve(true);
            })
            .catch((error) => {
              console.log("break 5");
              reject(false);
            });
        });
      },
    }
  });

  const [uploads, setUploads] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleImageChange = (event) => {
    setFiles(event.target.files);
    if (event.target.files && event.target.files[0]) {
      let images = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        images.push(URL.createObjectURL(file));
      }
      setUploads(images);
    }
  };

  // // TODO: manage the pathing for this component
  // const handleUpload = async (path) => {
  //   if (files.length === 0) {
  //     console.log("break 3");
  //     return;
  //   }
  //   setUploading(true);
  //   let promises = [];

  //   for (let i = 0; i < files.length; i++) {
  //     console.log("Path: ", `${path}/${GenerateUUID()}`);
  //     promises.push(supabase.storage
  //       .from(path.includes('reports') ? 'reports' : 'posts')
  //       .upload(`${path}/${GenerateUUID()}`, files[i]));
  //   };

  //   Promise.all(promises)
  //     .then(async () => {
  //       setUploading(false);
  //       setUploads(null);
  //       setFiles([]);
  //       onUpload();
  //       console.log("break 4");
  //       return true;
  //     })
  //     .catch((error) => {
  //       console.log("break 5");
  //       return false;
  //     });
  // };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: '10px' }}>
      {/* Input field */}
      <h4 style={styles.headingText}>Upload Images</h4>
      <p style={{ fontSize: "12px" }}>Maximum of 50mb: .png or .jpeg</p>
      <Form.Control type='file' onChange={handleImageChange} accept='image/png, image/jpeg' multiple={true} />
      {/* Images */}
      {uploads && uploads.map((image) => {
        console.log("Images: ", uploads);
        return <img key={image} className={`image`} src={image} alt='Preview' />
      })}
    </div>
  );
});

export default React.memo(ImageUpload);