import { useState, useEffect, forwardRef, useContext, useImperativeHandle } from 'react';
import { GetYears, GetVehicleData, GetParts, GetManfacturers } from '../Getters.js';
import Select from 'react-select';
import { SearchBarStyles as styles } from '../../styles.js';
import Form from 'react-bootstrap/Form';
import { searchStage } from '../../App.js';
import Button from 'react-bootstrap/Button';

const SearchbarMobile = forwardRef(({ }, ref) => {
    const [parts, setParts] = useState([]);
    const [splitDataObject, setSplitDataObject] = useState({}); //Object that contains the split data of the vehicle names
    const [manufacturers, setManufacturers] = useState([]); //List of manufacturers [String]

    //Search Variables
    const [year, setYear] = useState({ value: '', label: 'Select Year...' });
    const [manufacturer, setManufacturer] = useState({value: '', label: 'Select Manufacturer...'}); //Manufacturer is the manufacturer of the vehicle (first part of the name)
    const [make, setMake] = useState({value: '', label: 'Select Make...' }); //Make is the id of the vehicle
    const [part, setPart] = useState({value: '', label: 'Select Part...'});
    const [maxDistance, setMaxDistance] = useState(0); //To be reintroduced in a future update

    ref.current = {
        GetSearchValues: () => {
            const search = {
                year: year.value,
                manufacturer: manufacturer.value,
                make: make.value, //set this to be the number value (id) of the make
                part: part.value,
                distance: maxDistance,
                user_id: '',
            }
            console.log("Search Values: ", search)
            return search;
        },
    }

    useEffect(() => {
        async function InitilaizeSearchOptions() {
            const all_makes = await GetVehicleData();
            let first = await GetParts({ from: 0, to: 1000 })
            const all_parts = first

            setParts(all_parts);


            const split_data = all_makes.map((item) => {
                const splitName = item.name.split(' - ');

                //  object includes  the split name and the ID
                return {
                    id: item.id,
                    splitName: splitName,
                };
            });

            let modelsByManufacturer = {};

            split_data.forEach(item => {
                let manufacturer = item.splitName[0];
                let model = item.splitName[1];
                let id = item.id;

                // Check if the manufacturer already exists in the structure
                if (!modelsByManufacturer.hasOwnProperty(manufacturer)) {
                    modelsByManufacturer[manufacturer] = [];
                }
                // Add the model and its ID under the manufacturer
                modelsByManufacturer[manufacturer].push({ model: model, id: id });
            });

            //Get a list of the manufacturers
            const keys = Object.keys(modelsByManufacturer).map((item) => { return { value: item, label: item } });
            setManufacturers(keys);

            //each manufacturer contains an array of objects with the model and id of the model. 
            setSplitDataObject(modelsByManufacturer);
        }

        InitilaizeSearchOptions();
    }, []);


    //#region Logo State Control System
    const [stage, setStage] = useContext(searchStage);
    function CalculateStage() {
        let newStage = 0;
        if (year.value !== '') newStage++;
        if (make.value !== '') newStage++;
        if (part.value !== '') newStage++;
        if (maxDistance !== 0) newStage++;
        console.log("New Stage: ", newStage);
        setStage(newStage);
    }
    useEffect(() => {
        CalculateStage();
    }, [year, make, part, maxDistance]);
    //#endregion

    const GetYearsWithNull = () => {
        const years = GetYears().map((item) => {return {value: item, label: item}});
        years.unshift({value: '', label: 'Select Year...'});
        return years;
    }

    const GetManufacturersWithNull = () => {
        const keys = Object.keys(splitDataObject).map((item) => {return {value: item, label: item}});
        keys.unshift({value: '', label: 'Select Manufacturer...'});
        return keys;
    }

    const GetPartsWithNull = () => {
        const newParts = parts.map((item) => {return {value: item.id, label: item.part_name}});
        newParts.unshift({value: '', label: 'Select Part...'});
        return newParts;
    }

    const GetMakes = (models) => {
        if (models === undefined) return [];
        const makes = models.map((item) => {return {value: item.id, label: item.model}});
        makes.unshift({value: '', label: 'Select Vehicle...'});
        return makes;
    }

    return (
        <header style={styles.dropdownHeader}>
            <Form action="/action_page.php" style={styles.searchForm}>
            <Select 
                options={GetYearsWithNull()} 
                value={year} 
                onChange={setYear}
                placeholder={"Select Year..."}
                openMenuOnClick={true} />
            <Select 
                options={GetManufacturersWithNull()} 
                value={manufacturer} 
                onChange={setManufacturer}
                placeholder={"Select Manufacturer..."}
                openMenuOnClick={true} />
            {manufacturer.value !== '' && 
                <Select 
                    options={GetMakes(splitDataObject[manufacturer.value])} 
                    value={make} 
                    onChange={setMake}
                    placeholder={"Select Vehicle..."}
                    openMenuOnClick={true} />}
            {parts.length > 0 && <Select
                options={GetPartsWithNull()}
                value={part}
                onChange={setPart}
                placeholder={"Select Part..."}
                openMenuOnClick={true} />}
            </Form>
        </header>
    )
});



export default SearchbarMobile;
