import { useEffect, useState } from "react";
import { supabase } from "../../lib/api";
import { useUser } from "@supabase/auth-helpers-react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { ButtonStyles, FormStyles as styles } from "../../styles";
import { useNavigate } from "react-router-dom";


const AuthPasswordRecovery = () => {
    let navigate = useNavigate();
    const [helperText, setHelperText] = useState({ error: null, text: null });

    const user = useUser();
    useEffect(() => {
        console.log("User: ", user);
    }, [user]);

    //Parse the email from the url path
    var email = window.location.pathname.split("/")[2];
    //remove the last # from the email
    email = email.replace("#", "");

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handlePasswordReset = async () => {
        //Validate the input patterns for the email address and password
        if (!newPassword || !confirmPassword) {
            setHelperText({ error: true, text: "Please fill out all fields" });
            return;
        }

        if (newPassword !== confirmPassword) {
            setHelperText({ error: true, text: "Passwords do not match" });
            return;
        }

        console.log("Email: ", email);
        console.log("User Email: ", user.email);

        //Validate the user's email address against the auth email address
        if (user.email !== email) {
            setHelperText({ error: true, text: "Email address does not match the user's email" });
            return;
        }

        //Update the User's password
        const { data, error } = await supabase.auth
            .updateUser({ email: email, password: confirmPassword });

        //Handle the response
        if (error) {
            setHelperText({ error: true, text: error.message });
        } else {
            setHelperText({
                error: false,
                text: "Password has been reset!",
            });
            //Wait for 3 seconds and then redirect to the login page
            setTimeout(() => {
                navigate("/auth");
            }, 3000);
        }
    };

    return (
        <Container className={""}>
            <Form style={styles.formContainer}>
                <h1 style={styles.headingText}>Reset Password</h1>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <p>{email}</p>
                    <p style={{ fontSize: "12px" }}>We'll never share your email with anyone else.</p>
                    <div className="row">
                        <div className="col">
                            <Form.Label style={styles.leftAlignedLabel}>New Password:</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Form.Label style={styles.leftAlignedLabel}>Confirm Password:</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                    </div>
                </Form.Group>

                <button type='button' style={ButtonStyles.submitButton} onClick={handlePasswordReset}>
                    Reset Password
                </button>

                {!!helperText.text && (
                    <div
                        className={`border px-1 py-2 my-2 text-center text-sm ${helperText.error
                            ? "bg-red-100 border-red-300 text-red-400"
                            : "bg-green-100 border-green-300 text-green-500"
                            }`}
                    >
                        {helperText.text}
                    </div>
                )}
            </Form>

        </Container>
    );
};

export default AuthPasswordRecovery;