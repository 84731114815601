import { useState, useContext } from "react";
import { supabase } from "../../lib/api";
import { Form } from "react-bootstrap";
import { sessionContext } from "../../App";
import { useUser } from "@supabase/auth-helpers-react";
import AccountSettings from "./AccountSettings";
import { ButtonStyles, FormStyles as styles } from "../../styles";
import { useNavigate } from "react-router-dom";

const Auth = ({ loginPath, devMode }) => {
    let navigate = useNavigate();

    const [session, setSession] = useContext(sessionContext);
    const [helperText, setHelperText] = useState({ error: null, text: null });
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    async function signInWithEmail(event) {
        event.preventDefault();
        const { data, error } = await supabase.auth.signInWithPassword({
            email: email,
            password: password
        })

        if (error) {
            alert(error.message)
        }
        else {

            setSession(data);
            const redirectTo = loginPath === undefined ? `/` : `/${loginPath}`;
            navigate(redirectTo);
        }
    }

    const forgotPassword = async (e) => {
        // Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
        e.preventDefault();
        const email = prompt("Please enter your email:");

        if (email === null || email === "") {
            setHelperText({ error: true, text: "You must enter your email." });
        } else {
            let { error } = await supabase.auth.resetPasswordForEmail(
                email
            );
            if (error) {
                console.error("Error: ", error.message);
            } else {
                setHelperText({
                    error: false,
                    text: "Password recovery email has been sent.",
                });
            }
        }
    };

    return (


        <Form style={styles.formContainer}>
            {devMode ? (
                <div>
                    <h1 style={styles.headingText}>Developer Login</h1>
                </div>
            ) : (
                <div>
                    <h1 style={styles.headingText}>Sign In</h1>
                    <p>You can log in using your Private Auto Parts account below:</p>
                </div>
            )}



            <Form.Group className="mb-3">
                <div className="row">
                    <div className="col">
                        <Form.Label style={styles.leftAlignedLabel}>Email:</Form.Label>
                        <Form.Control style={styles.textInput} type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Form.Label style={styles.leftAlignedLabel}>Password:</Form.Label>

                        <Form.Control style={styles.textInput} type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>
            </Form.Group>
            <button
                type="button"
                style={{ margin: 8, display: 'block', background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={forgotPassword}
            >
                Forgot Password?
            </button>

            <button type="button" style={ButtonStyles.submitButton} onClick={(e) => signInWithEmail(e)}>
                Sign In
            </button>

            {!!helperText.text && (
                <div>
                    <div
                        className={`border px-1 py-2 my-2 text-center text-sm ${helperText.error
                            ? "bg-red-100 border-red-300 text-red-400"
                            : "bg-green-100 border-green-300 text-green-500"
                            }`}
                    >

                        {helperText.text}
                    </div>
                </div>

            )}
        </Form>
    );
};

export default Auth;