import './App.css';
import './palette.css';
import React, { useState, useEffect, createContext } from 'react';
import { supabase } from './lib/api.js';
import { useUser } from '@supabase/auth-helpers-react';
import { Dropdown, Button } from 'react-bootstrap';
import HomeScreen from './components/Screens/Home.js';
import CreatePost from './components/Screens/CreatePost.js';
import Auth from './components/Screens/Auth.js';
import AccountSettings from './components/Screens/AccountSettings.js';
import ReportPost from './components/Screens/ReportPost.js';
import AuthPasswordRecovery from './components/Screens/AuthPasswordRecovery.js';
import Messages from './components/Screens/Messages.js';
import EditPost from './components/Screens/EditPost.js';
import ViewPost from './components/Screens/ViewPost.js';
import AdminDashboard from './components/Screens/AdminDashboard.js';
import Registration from './components/Screens/Registration.js';
import ContactUs from './components/Screens/ContactUs.js';
import FAQs from './components/Screens/FAQs.js';
import UserIcon from './assets/user.png';
import Stage1 from './assets/Stage1.png'
import Stage2 from './assets/Stage2.png'
import Stage3 from './assets/Stage3.png'
import Stage4 from './assets/Stage4.png'
import DLC_Logo from './assets/DLC_Logo.png'
import { getUserData, isAdmin } from './components/Getters.js';
import { WebsiteHeaderStyles as styles, colorPalette, ButtonStyles } from './styles.js';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Link
} from 'react-router-dom';

export const sessionContext = React.createContext();
export const searchStage = React.createContext();
function App() {
  const navigate = useNavigate();
  const [session, setSession] = useState(null);
  const [stage, setStage] = useState(0);
  const stages = [Stage1, Stage2, Stage3, Stage4]
  const user = useUser();

  useEffect(() => {
    async function Initialize() {
      //Test the user data retrieval
      if (user != null) {
        const myData = await getUserData(user.id);
        if (myData == null) {
          //console.log("Session: ", user);
          //Redirect them to the registration page
          navigate('/sign-up');
        }
      }
    }
    Initialize();
  }, []);



  //#endregion

  //Get the server date/time and store it in the session
  const [date, setDate] = useState(new Date());

  //Function to check if the date is March 31, 2024 at 12:00:00
  const isDeadline = (date) => {
    const deadline = new Date('March 31, 2024 12:00:00');

    if (date >= deadline) {
      return true;
    }
    return false;
  }

  return (
    <sessionContext.Provider value={[session, setSession]}>
      <searchStage.Provider value={[stage, setStage]}>

        <div className="App" style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
          <header style={{
            marginBottom: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: '#F7F7F7'
          }}>
            <div className="d-flex justify-content-between" style={{ padding: 10, gap: 20 }}>
              <div className="d-flex align-items-center">
                <Link to='/'>
                  <img src={stages[stage]} alt="Logo" style={{ marginRight: '10px', maxHeight: '10rem', maxWidth: '10rem' }} />
                </Link>
                <h1 className="d-none d-md-block" style={styles.h1}>
                  <Link to='/' style={{textDecorationLine: 'none'}}><div style={{color: 'var(--primary_blue)', ...styles.h1Text}}>PrivateAutoParts</div><div style={{color: 'red', ...styles.h1Text}}>.</div><div style={{color: 'black', ...styles.h1Text}}>com</div></Link>
                </h1>
              </div>
              {/* Flex container for the profile menu and post button */}
              <div className="d-flex flex-sm-row flex-column align-items-center" style={{ gap: 20, marginRight: '10px' }}>
                <ProfileMenu />
                <Button type={'button'} style={{
                  ...ButtonStyles.saveButton,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }} onClick={() => navigate('/create-post')}>+ Post</Button>
              </div>
            </div>
          </header>
          <section style={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: '0%',
          }}>
            <Routes>
              <Route path="/" element={<HomeScreen context={'search'} />} />
              <Route path="/recover-password/:id" element={<AuthPasswordRecovery />} />
              <Route path="/create-post" element={<CreatePost />} />
              <Route path="/success" element={<CreatePost />} />
              <Route path="/edit/:id" element={<EditPost />} />
              <Route path="/view/:id" element={<ViewPost />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="/sign-up" element={<Registration />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/top-questions" element={<FAQs />} />
              <Route path="/my-posts" element={<HomeScreen context={'my-posts'} />} />
              <Route path="/favorites" element={<HomeScreen context={'favorites'} />} />
              <Route path="/user-profile/:id" element={<HomeScreen context={'user-profile'} />} />
              <Route path="/profile" element={<AccountSettings />} />
              <Route path="/report-post/:id" element={<ReportPost />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/messages/:name" element={<Messages />} />
              <Route path="/dashboard" element={<AdminDashboard />} />
              {/* Define any additional routes as needed */}
            </Routes>
          </section>
          <footer class="container-fluid text-white mt-5" style={{ backgroundColor: colorPalette.primary_blue }}>
            <div class="row text-center py-3">
              <div class="col-md-6 mt-md-0 mt-3">
                <p>developed by Digital Lift Consulting <a href="www.digitalliftconsulting.com" ><img style={{ height: 45}} src={DLC_Logo} alt="Digital Lift Consulting" /></a></p>
                <p class="text-uppercase">Copyright &copy; 2024 Private Auto Parts</p>
                <p>All rights reserved.</p>
              </div>

              <div class="col-md-6 mb-md-0 mb-3">
                <h5 class="text-uppercase">Support</h5>
                <ul className="list-unstyled">
                  <li><Link to="/contact-us" className="text-white">Contact Us</Link></li>
                </ul>
                <ul className="list-unstyled">
                  <li><Link to="/top-questions" className="text-white">FAQs</Link></li>
                </ul>
                <ul className="list-unstyled">
                  <li><Link to="/auth" className="text-white">Sign In</Link></li>
                </ul>
              </div>
            </div>
          </footer>
        </div>

      </searchStage.Provider>
    </sessionContext.Provider>
  );
}


function ProfileMenu() {
  const navigate = useNavigate();
  const goTo = (path) => () => navigate(path);
  const user = useUser();


  const [admin, setAdmin] = useState(false);

  const checkAdminStatus = async () => {
    if (user != null) {
      const isAdminUser = await isAdmin(user.id);
      setAdmin(isAdminUser);
    }
  };

  // run on mount or user change (sign in/out)
  useEffect(() => {
    checkAdminStatus();
  }, [user]);


  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      //console.log('Error signing out:', error.message);
    } else {
      navigate('/');
    }
  }

  if (user === null) {
    return (
      <div className='container-sm row'>
        <p><Link to="/sign-up">Register</Link> or <Link to="/auth">Sign In</Link></p>
      </div>
    );
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        style={{
          backgroundColor: colorPalette.action_blue,
          border: 'none',
          padding: '10px 20px',
          cursor: 'pointer',
          color: colorPalette.primary_white,
          borderRadius: '5px',
        }}
      >
        Profile
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as="button" onClick={goTo('/profile')}>Account Settings</Dropdown.Item>
        <Dropdown.Item as="button" onClick={goTo('/my-posts')}>My Posts</Dropdown.Item>
        <Dropdown.Item as="button" onClick={goTo('/messages')}>Messages</Dropdown.Item>
        <Dropdown.Item as="button" onClick={goTo('/favorites')}>Favorites</Dropdown.Item>
        {admin && (<Dropdown.Item as="button" onClick={goTo('/dashboard')}>Admin Controls</Dropdown.Item>)}
        <div className="dropdown-divider"></div>
        <Dropdown.Item onClick={handleSignOut}>Sign Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}


function Countdown() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [developerLogin, setDeveloperLogin] = useState(false);
  const deadline = new Date('April 6, 2024 12:00:00');
  const difference = +deadline - +currentDate;
  const remaining = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60)
  };

  return (
    <>
      <div style={{
        display: 'flex',
        padding: '30px',
        justifyContent: 'end',
        alignItems: 'center',
        gap: '10px',
        width: '100%',
      }}>
        <Button type={'button'} style={{ background: 'white', border: 'none', fontSize: '1rem', color: 'blue' }} onClick={() => setDeveloperLogin(!developerLogin)}><img src={UserIcon} alt={'developer login'} /></Button>
      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorPalette.primary_white,
        color: colorPalette.primary_blue,
        fontSize: '2rem'
      }}>
        <h1>Private Auto Parts</h1>
        <p>Coming Soon!</p>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
          }}>
            <p>{remaining.days} Days</p>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
          }}>
            <p>{remaining.hours} Hours</p>
          </div>
        </div>
        {developerLogin && (
          <Auth devMode={true} />
        )}
      </div>
    </>
  )
}


export default App;