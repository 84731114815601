import { Form, Button } from 'react-bootstrap';
import { useEffect, useState, createRef } from 'react'
import { GetYears, GetVehicleData, GetParts } from '../Getters.js';
import ImageUpload from '../WIP/ImageUpload.js';
import { useUser } from '@supabase/auth-helpers-react';
import Auth from './Auth.js';
import { supabase } from '../../lib/api.js';
import { PostPageStyles as styles, ButtonStyles } from '../../styles.js';
import {
    BrowserRouter as Router,
    useNavigate,
} from 'react-router-dom';
import LoadingModal from './LoadingModal.js';

/**
 * TODO:
 * - Test the routing for successful payment and failed payment
 * 
 */
const CreatePost = () => {
    const isAfterJune2024 = () => {
        const currentDate = new Date();
        const june2024 = new Date('June 1, 2024');
        return currentDate >= june2024;
    }

    
    const navigate = useNavigate();
    //Dropdown Menu Options
    const [makes, setMakes] = useState([]);
    const [parts, setParts] = useState([]);
    const imageRef = createRef(null);

    const [splitDataObject, setSplitDataObject] = useState({}); //Object that contains the split data of the vehicle names
    const [manufacturer, setManufacturer] = useState(""); //Manufacturer is the manufacturer of the vehicle (first part of the name)
    const [make, setMake] = useState(""); //Make is the id of the vehicle


    //Input Variables
    const [year, setYear] = useState("");
    const [part_id, setPart_ID] = useState("");
    const [stock_number, setstock_number] = useState("");
    const [description, setDescription] = useState("");
    const [discountCode, setDiscountCode] = useState(isAfterJune2024() ? "" : "PRIVATEPOSTER_2000_JANUARY");
    const [price, setPrice] = useState(0);
    const [currencyType, setCurrencyType] = useState('CAD'); // Default to CAD
    const [validationErrors, setValidationErrors] = useState({}); // New state for validation errors
    const [loading, setLoading] = useState({ disabled: false }); // New state for loading state

    //Get the window url
    const url = window.location.href;
    //Test to see if the url contains the path /success
    if (url.includes("/success")) {
        //Set a alert to notify the user of the successful transaction
        alert("Transaction Successful! Your post will be live shortly.");
    }
    else if (url.includes("?canceled=true")) {
        //Set a alert to notify the user of the failed transaction
        alert("Transaction Ended! Please try again.");
    }

    async function GetLocation() {
        const { data, error } = await supabase
            .from('userdata')
            .select('location, country')
            .eq('user_id', user.id)
            .single();

        if (error) {
            //console.log(error);
        }
        else {
            //console.log(data);
            return data;
        }
    }

    useEffect(() => {
        if (imageRef.current !== null && loading.disabled === true && loading.data !== undefined) {
            completeUpload(loading.data, loading.currency);
        }
    }, [imageRef]);

    //Create Post Function
    const handleCreatePost = async () => {
        if (!make) {
            alert("Please select a manufacturer and model before submitting.");
            return;
        }

        if (loading.disabled) {
            return;
        }
        setLoading({ disabled: true });
        //Now we must wait for the imageRef.current to finish mounting before we can proceed with the post creation.
        //Create a post packet to send to the server
        const post = {
            vehicle_id: make,
            year: year === "" ? GetYears()[0] : year,
            part: part_id === "" ? parts[0].id : part_id, //Should pass the part id instead for data normailization
            stock_number: stock_number,
            description: description,
            price: price,
            user_id: user.id,
            currencytype: currencyType === "CAD" ? "CAD" : "USD",
        }

        //Get the user's location and potential currency
        var currency = "cad";
        await GetLocation().then((result) => {
            post.location = result.location;
            currency = result.country === "Canada" ? "cad" : "usd";
        });

        //Create a temporary post in the database to store the cart item. -> if the transaction is successful, the cart item id will be used by the server to move it to the posts table.
        const { data, error } = await supabase.from('carts').insert(post).select();
        if (error) {
            alert("Error: " + error.message);
            setLoading({ disabled: false });
        }
        else {
            setLoading({ disabled: true, data: data, currency: currency });
        }
    }

    async function completeUpload(data, currency) {
        //Upload the images to the server
        let complete = await imageRef.current.UploadImages(`${user.id}/temp/${data[0].id}/`)
        //If the image upload was successful, then proceed to the payment page.
        if (complete === true) {
            //Set the currency on the packet
            data[0].currency = currency;
            data[0].discountCode = discountCode;
            //console.log("Currency: ", data[0].currency);
            //If the image path was added, then proceed to the payment page.
            fetch('/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    data[0]
                ),
            })
                .then((response) => {
                    if (response.ok) return response.json();
                    else return response.json().then((json) => Promise.reject(json));
                }).then(({ url }) => {
                    //Check the response url for own domain
                    if (url.includes("/my-posts")) {
                        navigate("/my-posts");
                    }
                    else {
                        window.location = url;
                    }
                }).catch((error) => {
                    console.error('Error:', error);
                    alert("Error: " + error.error);
                    setLoading({ disabled: false });
                });
        }
    }

    useEffect(() => {
        async function InitilaizeSearchOptions() {
            const all_makes = await GetVehicleData();
            const all_parts = await GetParts();
            setMakes(all_makes);
            setParts(all_parts);

            const split_data = all_makes.map((item) => {
                const splitName = item.name.split(' - ');

                //  object includes  the split name and the ID
                return {
                    id: item.id,
                    splitName: splitName,
                };
            });

            //console.log("Split Data: ", split_data)

            let modelsByManufacturer = {};

            split_data.forEach(item => {
                let manufacturer = item.splitName[0];
                let model = item.splitName[1];
                let id = item.id;

                // Check if the manufacturer already exists in the structure
                if (!modelsByManufacturer.hasOwnProperty(manufacturer)) {
                    modelsByManufacturer[manufacturer] = [];
                }

                // Add the model and its ID under the manufacturer
                modelsByManufacturer[manufacturer].push({ model: model, id: id });
            });

            //console.log(modelsByManufacturer);
            //each manufacturer contains an array of objects with the model and id of the model. 
            setSplitDataObject(modelsByManufacturer);
        }
        InitilaizeSearchOptions();
    }, []);

    {/* If User Exists: display the create post, if not, display the Auth Page */ }
    const user = useUser();
    if (user === null) {
        return (
            <Auth />
        )
    }

    return (
        <Form style={styles.formContainer}>
            <h1 style={styles.headingText}>New Post</h1>
            {/* TO be added in the future */}

            {isAfterJune2024() ? (
            <Form.Group controlId="vehicleDetails" style={{ flexDirection: 'column', display: 'flex', paddingInline: '20px' }}>
                <Form.Label style={styles.leftAlignedLabel}>Discount Code</Form.Label>
                <Form.Control
                    style={styles.textInput}
                    type={"text"}
                    value={discountCode}
                    placeholder="Discount Code"
                    onChange={(e) => { setDiscountCode(e.target.value) }}
                />
            </Form.Group>) : 
            (
            <Form.Group controlId="vehicleDetails" style={{ flexDirection: 'column', display: 'flex', paddingInline: '20px' }}>
                <Form.Label style={{ fontSize: '1rem' }}>Great news! We're offering <b>FREE</b> postings on our website until June 1st, 2024.</Form.Label>
                <Form.Label style={{ fontSize: '1rem' }}>Don't miss this chance to promote your products to our audience – start posting today!</Form.Label>
            </Form.Group>
            )}
            <Form.Group controlId="vehicleDetails" style={{ flexDirection: 'column', display: 'flex', paddingInline: '20px' }}>
                <Form.Label style={styles.leftAlignedLabel}>Manufacturer</Form.Label>
                <Form.Select
                    name="Manufacturer"
                    id="manufacturer"
                    style={styles.dropdown}
                    onChange={(e) => {
                        const selectedManufacturer = e.target.value;
                        setManufacturer(selectedManufacturer); // Updates manufacturer state
                        setMake('');
                    }}
                >
                    <option value={''}>Select Manufacturer...</option>
                    {Object.keys(splitDataObject).map(manufacturer => (
                        <option key={manufacturer} value={manufacturer}>{manufacturer}</option>
                    ))}
                </Form.Select>

                {manufacturer && (
                    <>
                        <Form.Label style={styles.leftAlignedLabel}>Model</Form.Label>

                        <Form.Select
                            name="Model"
                            id="model"
                            style={styles.dropdown}
                            onChange={(e) => {
                                const modelName = e.target.value;
                                const modelObj = splitDataObject[manufacturer].find(model => model.model === modelName);
                                setMake(modelObj ? modelObj.id : '');
                            }}
                        >
                            <option value={''}>Select Model...</option>
                            {splitDataObject[manufacturer]?.map((item) => (
                                <option key={item.id} value={item.model}>{item.model}</option>
                            ))}
                        </Form.Select>
                    </>
                )}

                <Form.Label style={styles.leftAlignedLabel}>Year</Form.Label>
                <Form.Select
                    name="Year"
                    id="year"
                    style={styles.dropdown}
                    onChange={(e) => setYear(e.target.value)}
                >
                    {GetYears().map((item) => {
                        return <option key={item} value={item}>{item}</option>;
                    })}
                </Form.Select>
                <Form.Label style={styles.leftAlignedLabel}>Part</Form.Label>
                {/* Part Category - Stored in the local Parts File */}
                <Form.Select
                    name="Part"
                    id="part"
                    style={styles.dropdown}
                    onChange={(e) => {
                        const selected = parts.find(part => part.part_name === e.target.value);
                        setPart_ID(selected.id)
                    }}
                >
                    {parts != [] &&
                        parts.map((item) => {
                            return <option key={item.id} value={item.part_name}>{item.part_name}</option>;
                        }
                        )}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="postDetails" style={{ flexDirection: 'column', display: 'flex', paddingInline: '20px', marginTop: '20px' }}>
                {/* Part ID - For the user to input */}
                <Form.Label style={styles.leftAlignedLabel}>Part ID</Form.Label>
                <Form.Control
                    style={styles.textInput}
                    type="text"
                    name="stock_number"
                    placeholder="Enter Stock #..."
                    onChange={(e) => { setstock_number(e.target.value) }} />
                {/* Conditionally render error message */}
                {validationErrors.stock_number && (
                    <Form.Text style={{ color: 'red' }}>
                        {validationErrors.stock_number}
                    </Form.Text>
                )}
                {/* Price - Number Input */}
                <Form.Label style={styles.leftAlignedLabel}>Asking Price</Form.Label>
                <Form.Control
                    style={styles.textInput}
                    type={"number"}
                    value={price}
                    placeholder="Enter Price"
                    onChange={(e) => { setPrice(e.target.value) }}
                />
                <p style={{ textAlign: 'start', fontSize: 'small' }}>The cost to create a post will be 3% of your listed price however the minimum cost to create a post is $0.50</p>

                {validationErrors.price && (
                    <Form.Text style={{ color: 'red' }}>
                        {validationErrors.price}
                    </Form.Text>
                )}
                <Form.Group controlId="currencySelection" style={{ flexDirection: 'column', display: 'flex' }}>
                    <Form.Label style={styles.leftAlignedLabel}>Currency</Form.Label>
                    <Form.Select
                        name="CurrencyType"
                        id="currencyType"
                        style={styles.dropdown}
                        value={currencyType}
                        onChange={(e) => setCurrencyType(e.target.value)}
                    >
                        <option value="CAD">CAD</option>
                        <option value="USD">USD</option>
                    </Form.Select>
                </Form.Group>
                {/* Description - Text Input */}
                <Form.Label style={styles.leftAlignedLabel}>Description</Form.Label>
                <Form.Control
                    as={"textarea"}
                    name="description"
                    rows={3}
                    style={{
                        padding: 10,
                        borderRadius: 5,
                        border: '1px solid #ccc',
                        marginBottom: 5,
                    }}
                    placeholder="Enter Description"
                    onChange={(e) => { setDescription(e.target.value) }} />
                {validationErrors.description && (
                    <Form.Text style={{ color: 'red' }}>
                        {validationErrors.description}
                    </Form.Text>
                )}


                {/* Image Upload - Maximum of 4 images - Use a ref to detect if images is not null */}
                <ImageUpload ref={imageRef} onUpload={() => { console.log("Upload Complete") }} />

            </Form.Group>

            {/* Submit Button */}
            {makes.length > 0 && parts.length > 0 &&
                <button
                    style={ButtonStyles.submitButton}
                    disabled={loading.disabled}
                    type="button"
                    onClick={() => { handleCreatePost() }}>{loading.disabled ? 'Submitting...' : 'Create Post'}</button>}
        </Form>
    )
}

export default CreatePost;