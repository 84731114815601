import { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GetActiveContacts, GetMessages } from "../Getters.js";
import { useUser, useSession } from "@supabase/auth-helpers-react";
import { supabase } from "../../lib/api.js";
import {
  colorPalette,
  MessagesStyles as styles,
  ButtonStyles,
} from "../../styles.js";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Auth from "./Auth.js";

const Messages = () => {
  const [contactsList, setContactsList] = useState([]);
  const user = useUser();
  const session = useSession();
  const { name } = useParams();

  var receiver_id = null;
  const handleInput = (e) => {
    e.target.style.height = "20px"; // Reset height to allow for shrinkage
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const [showConversations, setShowConversations] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function initialize() {
      const mySubscription = supabase
        .channel(`messages:${user.id}`)
        .on(
          "postgres_changes",
          { event: "INSERT", schema: "public", table: "messages" },
          (payload) => {
            //Test if the new messages have contact's from the active contacts list
            //If they do not then add them to the active contacts list
            let temp = payload.new;
            let existingContact = false;
            for (var i = 0; i < contactsList.length; i++) {
              if (
                contactsList[i].user_id == temp.sender_id ||
                contactsList[i].user_id == temp.receiver_id
              ) {
                existingContact = true;
                break;
              }
            }
            if (!existingContact) {
              FetchContacts();
            }
          }
        )
        .subscribe();
      await FetchContacts();
    }

    if (user != null && user != undefined) {
      initialize();
    }
  }, [user]);

  async function FetchContacts() {
    const temp = await GetActiveContacts(user.id); //Currently this returns ID's not names
    if (temp) {
      if (name != undefined) {
        //if the user_id is located in the temp data, then retreive the user_id
        for (var i = 0; i < temp.length; i++) {
          if (temp[i].username == name) {
            receiver_id = temp[i].user_id;
            break;
          }
        }
      }
      setContactsList(temp);
    }
  }

  //  styles
  const toggleButtonStyle = {
    display: isSmallScreen ? "block" : "none",
    cursor: "pointer",
    margin: "10px 0",
    backgroundColor: colorPalette.action_blue,
    border: "none",
    padding: "10px 20px",
    color: colorPalette.primary_white,
    borderRadius: "5px",
  };

  const conversationListStyle = {
    display: isSmallScreen && !showConversations ? "none" : "block", // Hide on small screens when showConversations is false
    height: "100%",
  };

  const chatHiddenStyle = {
    display: isSmallScreen && showConversations ? "none" : "block",
  };

  if (
    user == null ||
    user == undefined ||
    session == null ||
    session == undefined
  ) {
    return (
      <Container>
        <Auth />
      </Container>
    );
  }

  return (
    <Container style={{ paddingTop: 5, marginBottom: "40px" }}>
      <button
        style={toggleButtonStyle}
        onClick={() => setShowConversations(!showConversations)}
      >
        {showConversations ? "Hide Conversations" : "Show Conversations"}
      </button>
      <Row>
        <Col md={4}>
          <div style={conversationListStyle}>
            <h3
              style={{ color: colorPalette.primary_blue, marginBottom: "10px" }}
            >
              Contacts
            </h3>
            <div
              style={{
                height: "calc(100vh - 230px)",
                overflowY: "auto",
              }}
            >
              {contactsList.length > 0 &&
                contactsList.map((contact) => (
                  <div
                    key={contact.user_id}
                    style={{ marginBottom: "10px", width: "100%" }}
                  >
                    <ConversationButton
                      contact={contact}
                      myId={user.id}
                      isActive={contact.username === name}
                      onSelectConversation={() => {
                        if (isSmallScreen) {
                          setShowConversations(false);
                        }
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </Col>

        {name && (
          <Col md={8}>
            {/* Chat Window */}
            <div className="chat-window" style={chatHiddenStyle}>
              {/* Display chat messages - Used to show all the messages between this user and the other individual */}
              <ChatWindow
                key={name}
                myId={user?.id}
                receiverName={name}
                receiver_id={receiver_id}
              />
            </div>
          </Col>
        )}

        {name == undefined && (
          <Col md={8}>
            <div className="chat-window">
              <h1
                style={{
                  textAlign: "center",
                  color: colorPalette.primary_blue,
                }}
              >
                Select a Conversation
              </h1>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

/**
 * Creates a listen event for the messages channel and displays the messages
 * @param {*} param0
 * @returns
 */
const ChatWindow = ({ myId, receiverName, receiver_id }) => {
  //#region INITIALIZE
  const [messages, setMessages] = useState([]);
  const [receiver, setReceiver] = useState({
    id: receiver_id ? receiver_id : null,
    name: receiverName,
  });
  const [newMessage, setNewMessage] = useState(null);
  const chatWindowRef = useRef(null);
  const [text, setText] = useState(""); //The input text in the chat window
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const mySubscription = supabase
      .channel(`messages:${myId}`)
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "messages" },
        (payload) => {
          let temp = payload.new;
          console.log("New Message: ", temp);
          setNewMessage(temp);
        }
      )
      .subscribe();

    async function InitializeChat() {
      if (receiver.id == null || receiver.id == undefined) {
        const data = await fetch("/get-user-id", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: receiverName }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("USER ID Success:", data);
            return data;
          });

        if (data != undefined) {
          console.log("Step 2: ", data.id);
          setReceiver((prev) => {
            return { id: data.id, name: receiverName };
          });

          console.log("Looking up messages v1");
          const temp = await GetMessages(myId, data.id);
          if (temp) {
            setMessages(temp);
          }
        } else {
          console.log("Receiver ID not found");
        }
      } else {
        console.log("Looking up messages v2");
        const temp = await GetMessages(myId, receiver.id);
        if (temp) {
          console.log("Messages: ", temp);
          setMessages(temp);
        }
      }
    }

    if (myId != null || myId != undefined) {
      InitializeChat();
    }
  }, [myId, refresh]);

  useEffect(() => {
    if (newMessage == null || newMessage == undefined) {
      return;
    }
    console.log("Update Visible Messages: ", [...messages, newMessage]);
    setMessages([...messages, newMessage]);
  }, [newMessage]);
  //#endregion

  async function SendMessage() {
    console.log("Sending Message: ", text);
    if (text == "") {
      alert("Please enter a message to send!");
      return;
    }
    //Validate the input data
    const payload = {
      content: text,
      sender_id: myId,
      receiver_id: receiver.id,
    };

    //Generate the payload
    const { data, error } = await supabase.from("messages").insert(payload);
    if (error) {
      return;
    }
    // There would be no data since we are just inserting and not selecting.
    setText("");
    //This is to ensure that the chat window refreshes after a message is sent. There was a known issue where if there were no messages in the chat window, the chat window would not refresh.
    if (messages.length == 0) {
      console.log("Refreshing Chat Window");
      setRefresh(!refresh);
    }
  }

  //#region RENDER
  const getColor = (message) => {
    if (message.sender_id === myId) {
      return colorPalette.primary_blue;
    }
    return colorPalette.primary_white;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      const { current: chatWindow } = chatWindowRef;
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, [messages]);

  return (
    <div>
      <h2
        className="conversation-title"
        style={{
          textAlign: "center",
          color: colorPalette.primary_blue,
          padding: "10px",
          margin: 0,
        }}
      >
        {receiver.name}
      </h2>
      <div
        className="chat-window"
        ref={chatWindowRef}
        style={styles.chatContainer}
      >
        {messages.length > 0 &&
          messages.map((message) => (
            <div
              key={message.created_at + message.content}
              className={`chat-message ${
                message.sender_id === myId ? "sent" : "received"
              }`}
              style={{
                backgroundColor: getColor(message),
                color:
                  message.sender_id === myId
                    ? "white"
                    : colorPalette.primary_black,
                textAlign: message.sender_id === myId ? "right" : "left",
                borderRadius: 5,
                padding: 10,
                margin: "10px 10px", // Adjust margin to center messages and provide spacing
                maxWidth: "70%", // Message max width
                alignSelf:
                  message.sender_id === myId ? "flex-end" : "flex-start", // Align self to push messages to either end
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                className="message-content"
                style={{
                  wordWrap: "break-word", // Ensure long words do not overflow
                }}
              >
                {message.content}
              </span>
              <span
                style={{
                  fontSize: "x-small",
                }}
                className="timestamp"
              >
                {formatDate(message.created_at)}
              </span>
            </div>
          ))}
      </div>

      <div className="chat-input">
        <textarea
          style={styles.chatInput}
          placeholder="Type a message..."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />{" "}
        <button
          type="button"
          style={ButtonStyles.submitButton}
          onClick={SendMessage}
        >
          Send
        </button>
      </div>
    </div>
  );
  //#endregion
};

/**
 * Displays a list item component of conversations that the user is a part of
 * @param {*} conversation Must contain the following properties: sender, receiver, created_at
 **/
const ConversationButton = ({
  contact,
  myId,
  isActive,
  onSelectConversation,
}) => {
  const navigate = useNavigate();

  const buttonStyle = {
    backgroundColor: isActive
      ? colorPalette.action_blue_highlighted
      : colorPalette.action_blue, // Use red if active, otherwise blue
    border: "none",
    padding: "1px 5px",
    cursor: "pointer",
    color: colorPalette.primary_white,
    borderRadius: "5px",
    width: "100%",
  };

  let targetUrl = `/messages/${contact.username}`;

  return (
    <button
      type="button"
      onClick={() => {
        navigate(targetUrl);
        onSelectConversation();
      }}
      style={buttonStyle}
    >
      <div
        className="conversation"
        style={{
          backgroundColor: isActive
            ? colorPalette.action_blue_highlighted
            : colorPalette.primary_blue, // Adjust div background as well if needed
          color: "white",
          fontWeight: "bold",
          textAlign: "center",
          borderRadius: 5,
          padding: 5,
          margin: 10,
        }}
      >
        <span className="sender">{contact.username}</span>
        <br />
      </div>
    </button>
  );
};

export default Messages;
