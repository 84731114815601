import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './palette.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { supabase } from './lib/api.js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter


const stripePromise = loadStripe('pk_test_51ObVBdFgkDaDQaDIy7ayVmPK3eklVUJZrXy6I008luVFHpEZpLkL6WoFO0dllyqYmdlQbrP4gU8uDyuwvJ6aVBqo00Yb4tCHgy');
const root = ReactDOM.createRoot(document.getElementById('root'));

//May need to lookup the proper way to load the client_secret from the server.
//Unsure if this is a token generated on the server or if it is a key that is stored in the server.

root.render(
  <React.StrictMode>
    <Router>
      <Elements stripe={stripePromise}>
        <SessionContextProvider supabaseClient={supabase}>
          <App />
        </SessionContextProvider>
      </Elements>
    </Router>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
