import Searchbar from "../WIP/Searchbar";
import PostTable from "../WIP/PostTable";
import { useEffect, useRef, useState } from "react";
import { supabase } from "../../lib/api";
import {
  GetImages,
  LoadPosts,
  LoadPostsV2,
  GetNearbyPosts,
  GetPostData,
} from "../Getters";
import { Button } from "react-bootstrap";
import { useSessionContext, useUser } from "@supabase/auth-helpers-react";
import { colorPalette, ButtonStyles } from "../../styles";
import { useParams } from "react-router-dom";
import SearchbarMobile from "../WIP/SearchbarMobile";

/**
 * TODO:
 * 1. Include a filter for distance to the seller
 * 2. Include an Image Field
 * @returns
 */
// Home Screen - Fails - input is a void element tag and must neither have `children` nor use `dangerouslySetInnerHTML`.
function HomeScreen({ context }) {
  let { id: user_id } = useParams();
  const { isLoading, session } = useSessionContext();
  const searchRef = useRef(null);
  const [posts, setPosts] = useState([]); //Array of post objects
  const [currentRange, setCurrentRange] = useState({
    page: 1,
    from: 0,
    to: 9,
    rangeDiff: 10,
  }); //Current range of posts to display
  const [location, setLocation] = useState({ lat: 0, long: 0 }); //User's location

  /**
   * STEP 1
   * This is used to handle running the search for the posts. It uses the LoadPosts function to get the posts data and return it in the following format:
   *
   * @returns The Posts data based on the search values and the page context
   */
  const handleSearch = async () => {
    if (isLoading) return;
    if ((session == null || session == undefined) && context === "my-posts") {
      return;
    }
    let search = GetSearchValues();
    //Setup the range of posts to display
    search.from = currentRange.from;
    search.to = currentRange.to;
    //Get the posts
    GetPosts(search);
  };

  //STEP 2 - Get the search values based on the context
  function GetSearchValues() {
    //Get the search values from the searchbar
    var search = {
      year: "",
      make: "",
      part: "",
      location: "",
      user_id: "",
      favorites: false,
    };

    switch (context) {
      case "search":
        //Get the searchbar options and set the search object
        search = searchRef.current.GetSearchValues();
        search.user_id = session?.user.id;
        break;
      case "my-posts":
        //Check if the user is logged in
        search.user_id = session?.user.id;
        break;
      case "user-profile":
        search.user_id = user_id;
        break;
      case "favorites":
        search.user_id = session?.user.id;
        search.favorites = true;
        break;
      default:
        search = searchRef.current.GetSearchValues();
        break;
    }
    return search;
  }

  //STEP 3 - Get the posts based on the search values
  async function GetPosts(search) {
    var data =
      context === "search"
        ? await LoadPostsV2(search)
        : await LoadPosts(search);

    console.log("Data: ", data);
    if (data == null) {
      setPosts([]);
      return;
    }

    const images = await SetImagesForPosts(data);

    data.forEach((post, index) => {
      post.images = images[index];
    });

    //Now get the distances for all of the returned posts using the "distance_query_id" or the "distance_query_no_id"
    let getDistancePromises = [];
    data.forEach((post) => {
      getDistancePromises.push(GetPostDistance(post, location));
    });

    const distances = await Promise.all(getDistancePromises);
    data.forEach((post, index) => {
      //Convert the distance to kilometers
      let km_distance = distances[index][0].dist_meters / 1000000;
      post.distance = km_distance.toFixed(2);
    });

    setPosts(data);
    return data;
  }

  //STEP 4 - Return a promise that uses the supabase RPC function "get_post_distance" to get the distance between the user and the post
  async function GetPostDistance(post, location) {
    const { data, error } = await supabase.rpc("get_post_distance", {
      lat: location.lat,
      long: location.long,
      post_id: post.id,
    });
    if (error) {
      //console.log("Error: ", error);
      return null;
    }
    return data;
  }

  //STEP 5 - Get the images for the posts
  async function SetImagesForPosts(data) {
    if (data == null || data.length == 0) return [];

    let promises = [];

    for (let i = 0; i < data.length; i++) {
      promises.push(GetImages(`${data[i].user_id}/${data[i].id}`));
    }

    const images = await Promise.all(promises);
    return images;
  }

  //#region CONTROLS
  const SearchNext = async () => {
    const search = GetSearchValues();

    //Setup the range of posts to display
    search.from =
      currentRange.from + currentRange.rangeDiff * currentRange.page;
    search.to = currentRange.to + currentRange.rangeDiff * currentRange.page;

    //Load the posts data
    let data = await GetPosts(search);

    //Set the posts state
    if (data != null) {
      let page = currentRange.page + 1;
      setCurrentRange({
        page: page,
        from: search.from,
        to: search.to,
        rangeDiff: 10,
      });
    }
  };

  const SearchPrev = async () => {
    const search = GetSearchValues();

    //Setup the range of posts to display
    search.from = currentRange.from - currentRange.rangeDiff;
    search.to = currentRange.to - currentRange.rangeDiff;

    //Load the posts data
    let data = await GetPosts(search);

    //Set the posts state
    if (data != null) {
      let page = currentRange.page - 1;
      if (page < 1) page = 1;
      setCurrentRange({
        page: page,
        from: search.from,
        to: search.to,
        rangeDiff: 10,
      });
    }
  };
  //#endregion

  //#region Use Effects
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      getLocation_success,
      getLocation_failure,
      { enableHighAccuracy: true }
    );
  }, []);

  const getLocation_success = async (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const location = { lat: latitude, long: longitude };
    setLocation(location);
  };

  const getLocation_failure = (error) => {
    setLocation({ latitude: 43, longitude: -79 });
  };

  // useEffect(() => {
  //   if (context === 'search' && isLoading === false) {
  //     handleSearch();
  //   }
  // }, [isLoading]);

  useEffect(() => {
    if (
      context === "my-posts" ||
      context === "favorites" ||
      context === "user-profile"
    ) {
      setPosts([]);
      handleSearch();
    } else if (context === "search") {
      setPosts([]);
    }
  }, [context]);
  //#endregion

  const screenWidth = window.innerWidth;

  const ReturnTable = () => {
    if (posts.length == 0) {
      return null;
    } else {
      return <PostTable key={context} posts={posts} />;
    }
  };

  return (
    <div style={{ paddingRight: 20, paddingLeft: 20, width: "100%" }}>
      <div>
        {context === "search" && (
          <div
            className="d-flex flex-column flex-sm-row align-items-center border rounded-3"
            style={{ padding: "10px" }}
          >
            <SearchbarMobile ref={searchRef} className="flex-grow-1" />
            <div className="mt-2 mt-sm-0" style={{ marginLeft: "10px" }}>
              <button
                type="button"
                style={ButtonStyles.submitButton}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        )}
        {context === "my-posts" && (
          <h1 style={{ color: colorPalette.primary_blue }}>My Posts</h1>
        )}
        {context === "favorites" && (
          <h1 style={{ color: colorPalette.primary_blue }}>Favorites</h1>
        )}
      </div>
      <main style={{ display: "flex", width: "100%", minHeight: "45vh" }}>
        {ReturnTable()}
      </main>
      <footer
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          {currentRange.page !== 1 && (
            <button
              type="button"
              style={ButtonStyles.submitButton}
              onClick={SearchPrev}
            >
              Previous
            </button>
          )}
          {posts.length > 0 && (
            <p style={{ margin: 20 }}>Page: {currentRange.page}</p>
          )}
          {posts.length === currentRange.rangeDiff && (
            <button
              type="button"
              style={ButtonStyles.submitButton}
              onClick={SearchNext}
            >
              Next
            </button>
          )}
        </div>
      </footer>
    </div>
  );
}

export default HomeScreen;

// Add a system to notify user's when a 1 year has passed since they've posted an item - Extra to contract
// Hide the sort by distances until Andrew is confident with his supply
// FAQ page
// Terms and Conditions
// Privacy Policy
// Contact Us
