import { useState, useEffect, useRef } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { GetPostData } from "../Getters";
import ImageUpload from "../WIP/ImageUpload";
import { supabase } from "../../lib/api";
import { useUser } from '@supabase/auth-helpers-react';
import { FormStyles as styles, ButtonStyles } from "../../styles";
import { useNavigate } from "react-router-dom";

/**
 * System needs the following inputs:
 * 1. Post ID
 * 2. Reason for reporting
 * 3. Description of the issue
 * 4. Reporters email address
 * 5. Images of the issue
 * 
 * The system should store these reports in a database table for review by an admin
 */
const ReportPost = () => {
    let navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [email, setEmail] = useState(""); //Email of the reporter
    const [description, setDescription] = useState(""); //Description of the issue
    const [reason, setReason] = useState("Spam"); //Reason for reporting
    const imageRef = useRef(null);
    const user = useUser();

    useEffect(() => {
        async function Initialize() {
            // Get the post data from the database using the post id in the url
            const postId = window.location.pathname.split('/').pop();

            // Validate the post id by using regex to check if it is a valid integer
            if (!/^\d+$/.test(postId)) {
                console.log("Invalid post id");
                return null;
            }

            await GetPostData(postId)
                .then((data) => {
                    setPost(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        Initialize();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        //Create the report object
        const report = {
            post_id: post.id,
            user_id: user.id,
            reason: reason,
            description: description,
            email: email,
        }
        //Submit the report
        const { data, error } = await supabase.from('reports').insert(report).select();
        if (error) {
            console.log(error);
        }
        else {
            console.log("Report Submitted");
            if (data[0]) {
                const images = await imageRef.current.UploadImages(`reports/${data[0].id}`);

                if (images == true) {
                    console.log("Images Uploaded");
                    //Create an alert to let the user know the report was submitted then redirect to the home page after selecting ok
                    alert("Report Submitted");
                    navigate("/");

                }
            }

        }
    }

    if (post == null) {
        return (
            <></>
        );
    }

    return (
        <Form style={styles.formContainer}>
            <h1 style={styles.headingText}>Report Post</h1>

            <Form.Group>
                <Form.Label style={styles.leftAlignedLabel}>Why is this post being reported?</Form.Label>
                <div>
                    <button
                        type="button"
                        style={reason === "Spam" ? ButtonStyles.optionSelectedButton : ButtonStyles.optionNotSelectedButton}
                        onClick={() => { setReason("Spam") }}
                    >
                        This post is spam
                    </button>
                    <button
                        type="button"
                        style={reason === "Scam" ? ButtonStyles.optionSelectedButton : ButtonStyles.optionNotSelectedButton}
                        onClick={() => { setReason("Scam") }}
                    >
                        This post looks like a scam
                    </button>
                    <button
                        type="button"
                        style={reason === "Other" ? ButtonStyles.optionSelectedButton : ButtonStyles.optionNotSelectedButton}
                        onClick={() => { setReason("Other") }}
                    >
                        Other
                    </button>
                </div>
            </Form.Group>
            <Form.Group>
                <Form.Label style={styles.leftAlignedLabel}>Help us understand the problem.</Form.Label>
                <Form.Control style={styles.textInput} as="textarea" rows={3} placeholder="Please describe the issue" onChange={(e) => { setDescription(e.target.value) }} />
            </Form.Group>
            <Form.Group>
                <Form.Label style={styles.leftAlignedLabel}>Enter your email:</Form.Label>
                <Form.Control style={styles.textInput} type="email" placeholder="" value={email} onChange={(e) => { setEmail(e.target.value) }}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label style={styles.leftAlignedLabel}>Images:</Form.Label>
                <ImageUpload ref={imageRef} onUpload={() => { console.log("Upload Complete") }} />
            </Form.Group>

            <button type="button" style={ButtonStyles.submitButton} onClick={(e) => { handleSubmit(e) }}>
                Submit Report
            </button>
        </Form>
    )
};

export default ReportPost;